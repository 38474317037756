import { useDownloadFile } from "@/data-access/core/useDownloadFile";
import { useFeature } from "@/lib/feature-management";
import { format, toZonedTime } from "date-fns-tz";

// @Components
import FilterPill from "@/components/ui/data-display/filterPill";
import DateRangePicker, {
  type onApplyFn,
} from "@/components/ui/date-range-picker";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";

// @Icons
import {
  DotsThree,
  Export,
  FloppyDisk,
  Funnel,
  Gear,
} from "@phosphor-icons/react";

// @Stores
import useAuth from "@/store/authSlice";
import useFeedPanelStore from "@/store/feedPanelSlice";
import useFeedStore, {
  useFeedName,
  useFeedPercentage,
  useFilteredFeedItems,
  useArticleCount,
} from "@/store/useFeedStore";

// @Utils
import { addCommasToNumbers } from "@/lib/utils/prettyName";
import { getDateRangeType } from "@/store/feedHelpers";

import { Skeleton } from "@/components/ui/feedback/skeleton.tsx";
// @Types
import type { CategoryAppliedFilter, FilterData } from "@/store/feedHelpers";

const FeedFilterHeader = ({ filterData }: { filterData: FilterData[] }) => {
  const hasChartAccess = useFeature("enable-charts", false);

  const [openEditPanel] = useFeedPanelStore((state) => [state.openEditPanel]);

  // export the feed
  const [
    categoryAppliedFilters,
    endDate,
    feedId,
    filterDays,
    isCustomDaysRange,
    startDate,
    updateCategoryAppliedFilters,
    updateDateRange,
    updateRelationship,
    setPeriod,
    isFetchingFeed,
    feedVolume,
  ] = useFeedStore((state) => [
    state.categoryAppliedFilters,
    state.endDate,
    state.feedId,
    state.filterDays,
    state.isCustomDaysRange,
    state.startDate,
    state.updateCategoryAppliedFilters,
    state.updateDateRange,
    state.updateRelationship,
    state.setPeriod,
    state.fetchingFeed,
    state.feedVolume,
  ]);

  // feed details
  const feedName = useFeedName();
  const { feedPercentage, feedVolumePercentage } = useFeedPercentage();
  const filteredFeedItems = useFilteredFeedItems();
  const totalArticleCount = useArticleCount();

  // user
  const user = useAuth((state) => state.user);
  const tenant = user?.tenants?.[0]?.slug;

  const { ref, fileUrl, download, fileName } = useDownloadFile({
    onError: (e) => console.error(e),
    getFileName: () => `${feedName}-${new Date().toISOString()}-export.csv`,
  });

  const downloadFile = () => {
    let endpoint = `/articles/feed/${feedId}/export/?daysFilter=${filterDays}`;
    if (startDate && endDate) {
      const formattedStartDate = format(
        toZonedTime(startDate, "UTC"),
        "yyyy-MM-dd",
      );
      const formattedEndDate = format(
        toZonedTime(endDate, "UTC"),
        "yyyy-MM-dd",
      );
      endpoint = `/articles/feed/${feedId}/export/?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    }
    download(endpoint, tenant);
  };

  const onApply: onApplyFn = (range) => {
    setPeriod(getDateRangeType(range.from, range.to, range.days));
    updateDateRange(range.days, range.from, range.to, range.isCustom);
  };

  // render filter pills
  const generateFilterPill = (
    { selectedOptions, categoryKey }: FilterData,
    categoryAppliedFilters: CategoryAppliedFilter,
    updateCategoryAppliedFilters: (filters: CategoryAppliedFilter) => void,
    updateRelationship: (key: string, relationship: "AND" | "OR") => void,
  ) => (
    <FilterPill
      key={categoryKey}
      title={categoryKey}
      relationship={categoryAppliedFilters[categoryKey]?.relationship ?? "AND"}
      selectedOptions={selectedOptions}
      setSelectedOptions={(options) => {
        if (options) {
          const newCategoryAppliedFilters = {
            ...categoryAppliedFilters,
            [categoryKey]: {
              ...categoryAppliedFilters[categoryKey],
              selected: options,
              relationship:
                categoryAppliedFilters[categoryKey]?.relationship ?? "AND",
              itemCounts: categoryAppliedFilters[categoryKey]?.itemCounts || {},
            },
          };
          updateCategoryAppliedFilters(newCategoryAppliedFilters);
        } else {
          updateCategoryAppliedFilters({
            ...categoryAppliedFilters,
            [categoryKey]: {
              ...categoryAppliedFilters[categoryKey],
              selected: [],
              relationship: "AND",
              itemCounts: categoryAppliedFilters[categoryKey]?.itemCounts || {},
            },
          });
        }
      }}
      advanced={true}
      setUpdateRelationships={(relationship) =>
        updateRelationship(categoryKey, relationship)
      }
    />
  );

  // render feed percentage
  const renderFeedPercentage = () => {
    if (feedVolumePercentage && feedVolume) {
      return (
        <span>
          {`${addCommasToNumbers(feedVolume)} of ${addCommasToNumbers(
            totalArticleCount,
          )} (${feedVolumePercentage})%`}
        </span>
      );
    }

    return (
      <span>
        {`${addCommasToNumbers(
          filteredFeedItems.length,
        )} of ${addCommasToNumbers(totalArticleCount)} ${
          feedPercentage !== 100 && !Number.isNaN(feedPercentage)
            ? `(${feedPercentage}%) `
            : ""
        }`}
      </span>
    );
  };

  return (
    <div className="max-w-[956px] lg:mx-auto top-0 z-30 sticky px-3 bg-white">
      <div className={"flex items-end justify-between pb-4"}>
        <div className="flex flex-row items-center gap-1 pt-4">
          <Funnel className="h-4 w-4 mr-2 text-slate-500" />
          <span className={"text-xs text-gray-700 mr-2"}>
            Filters Applied:{" "}
          </span>
          {filterData.map((data) =>
            generateFilterPill(
              data,
              categoryAppliedFilters,
              updateCategoryAppliedFilters,
              updateRelationship,
            ),
          )}
        </div>
        <a href={fileUrl} download={fileName} className="hidden" ref={ref}>
          download
        </a>
        {hasChartAccess && (
          <DropdownMenu>
            <DropdownMenuTrigger className={"mr-3"}>
              <DotsThree size={24} />
            </DropdownMenuTrigger>
            <DropdownMenuPortal>
              <DropdownMenuContent className={"min-w-56"} sideOffset={5}>
                <DropdownMenuItem
                  className={"text-xs text-gray-600 pl-4"}
                  disabled
                >
                  More Actions
                </DropdownMenuItem>
                <DropdownMenuItem className={"cursor-pointer pl-4"}>
                  <FloppyDisk size={16} className={"text-gray-600"} />
                  Save as subfeed
                </DropdownMenuItem>
                <DropdownMenuItem
                  className={"cursor-pointer pl-4"}
                  onClick={downloadFile}
                >
                  <Export size={16} className={"text-gray-600"} />
                  Export
                </DropdownMenuItem>
                <DropdownMenuItem
                  className={"cursor-pointer pl-4"}
                  onClick={openEditPanel}
                >
                  <Gear size={16} className={"text-gray-600"} />
                  Settings
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenuPortal>
          </DropdownMenu>
        )}
      </div>

      {hasChartAccess && (
        <div className={"flex justify-between ml-1"}>
          {isFetchingFeed ? (
            <Skeleton className="w-32 h-5 bg-slate-300/50" />
          ) : (
            <div className={"flex flex-col text-xs"}>
              <span className={"font-semibold pb-2 tracking-wide"}>
                Total Mentions Over Time
              </span>
              {renderFeedPercentage()}
            </div>
          )}
          <div className={"flex flex-col"}>
            <DateRangePicker
              filterDays={filterDays}
              isCustomDaysRange={isCustomDaysRange}
              onApply={onApply}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedFilterHeader;
