import { cn } from "@/lib/utils";
import { useEffect, useRef, useState } from "react";

// Professional data visualization generation
const generateDataVisualization = () => {
  const gridSize = 5;
  const data: Array<{
    x: number;
    y: number;
    intensity: number;
    color: string;
  }> = [];

  for (let x = 0; x < gridSize; x++) {
    for (let y = 0; y < gridSize; y++) {
      data.push({
        x: (x + 0.5) * (100 / gridSize),
        y: (y + 0.5) * (100 / gridSize),
        intensity: Math.random() * 0.7,
        color: `oklch(70% 0.15 ${260 + Math.random() * 40} / 0.2)`,
      });
    }
  }

  return data;
};

const BackgroundGradientLayout = ({
  children,
  className = "",
}: { children: React.ReactNode | React.ReactNode[]; className?: string }) => {
  const [animationPhase, setAnimationPhase] = useState(0);
  const dataPoints = useRef(generateDataVisualization());

  useEffect(() => {
    const animationInterval = setInterval(() => {
      setAnimationPhase((prev) => (prev + 1) % 4);
    }, 2000);

    return () => clearInterval(animationInterval);
  }, []);

  return (
    <div className={cn("w-full h-full overflow-hidden relative", className)}>
      {/* Sophisticated Base Gradient */}
      <div
        className="absolute inset-0 overflow-hidden"
        style={{
          background: `linear-gradient(
            to bottom right, 
            oklch(95% 0.2 280 / 30%), 
            oklch(90% 0.15 290 / 22%), 
            oklch(85% 0.1 300 / 17%)
          )`,
        }}
      >
        {/* Enterprise-Grade Squares */}
        <div className="absolute inset-0 pointer-events-none">
          {dataPoints.current.map((point, index) => {
            const phaseOffset = index % 4;
            const isActive = phaseOffset === animationPhase;

            return (
              <div
                key={index}
                className="absolute transition-all duration-1000 ease-in-out"
                style={{
                  left: `${point.x}%`,
                  top: `${point.y}%`,
                  width: `${20 + point.intensity * 30}px`,
                  height: `${20 + point.intensity * 30}px`,
                  backgroundColor: point.color,
                  opacity: isActive ? 0.3 + point.intensity * 0.4 : 0.1,
                  transform: isActive
                    ? `scale(${1 + point.intensity * 0.1})`
                    : "scale(1)",
                  border: `1px solid ${point.color}`,
                  boxShadow: isActive
                    ? `0 0 15px ${point.color}`
                    : `0 0 10px ${point.color}`,
                }}
              />
            );
          })}
        </div>

        {/* Subtle Connection Lines */}
        <div className="absolute inset-0 pointer-events-none opacity-10">
          <svg className="absolute inset-0">
            {dataPoints.current.slice(0, 25).map((start, i) => {
              const end = dataPoints.current[i + 25] ??
                dataPoints.current[0] ?? { x: start.x, y: start.y };
              return (
                <line
                  key={i}
                  x1={`${start.x}%`}
                  y1={`${start.y}%`}
                  x2={`${end.x}%`}
                  y2={`${end.y}%`}
                  stroke={start.color}
                  strokeWidth={0.5}
                  strokeOpacity={0.05}
                  strokeDasharray="2,2"
                />
              );
            })}
          </svg>
        </div>
      </div>

      <div className="z-10 relative">{children}</div>
    </div>
  );
};

export default BackgroundGradientLayout;
