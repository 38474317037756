import { isDevelopment } from "@/config";
import type {
  FeedDataTermsType,
  FeedWithAugTypes,
} from "@/data-access/news/newsFeeds";
import { isWithinRange } from "@/store/feedHelpers.ts";
import { derive } from "derive-zustand";
import { create, useStore } from "zustand";
import { devtools } from "zustand/middleware";
import {
  type DerivedFilterItem,
  type FeedSlice,
  createFeedSlice,
} from "./feedSlice";

const useFeedStore = create<FeedSlice>()(
  devtools((...a) => ({ ...createFeedSlice(...a) }), {
    name: "feedStore",
    enabled: isDevelopment,
    store: "feed",
  }),
);

export default useFeedStore;

// const deriveStoryCount = derive<number>((get) => {
//   const { feedItems } = get(useFeedStore);
//   return feedItems.filter((item) => item.itemType === "STORY").length;
// });

const deriveArticleCount = derive<number>((get) => {
  const { feedItems, removalArr } = get(useFeedStore);
  return feedItems.length - removalArr.length;
});

const deriveFilterOrSearch = derive<boolean>((get) => {
  const { categoryAppliedFilters, searchTerms } = get(useFeedStore);
  return (
    (categoryAppliedFilters
      ? Object.values(categoryAppliedFilters).some(
          (filter) => (filter?.selected?.length ?? 0) > 0,
        )
      : false) || searchTerms.length > 0
  );
});

const deriveFilteredFeedItems = derive<Partial<FeedWithAugTypes>[]>((get) => {
  const {
    filterFeedIdSearch,
    feedItems,
    removalArr,
    sortOrder,
    feedVolumeSelectedDate,
  } = get(useFeedStore);

  const isFilteredOrSearched = get(deriveFilterOrSearch);

  // Helper function to filter items based on removal array
  const filterOutRemovedItems = <T>(
    items: T[],
    predicate: (item: T) => boolean,
  ) => items.filter(predicate);

  // Helper function to sort items based on sortOrder
  const sortItems = (
    a: Partial<FeedWithAugTypes>,
    b: Partial<FeedWithAugTypes>,
  ): number => {
    switch (sortOrder) {
      case "SCORE_DESC":
        return (b.maxScore || 0) - (a.maxScore || 0);
      case "SCORE_ASC":
        return (a.maxScore || 0) - (b.maxScore || 0);
      case "DATE_DESC":
      case "DATE_ASC": {
        const dateA = new Date(a.articleLastUpdateDate || 0).getTime();
        const dateB = new Date(b.articleLastUpdateDate || 0).getTime();
        return sortOrder === "DATE_DESC" ? dateB - dateA : dateA - dateB;
      }
      default:
        return 0;
    }
  };

  // If filtered or searched, transform items accordingly
  const filteredOrSearchedItems = isFilteredOrSearched
    ? filterFeedIdSearch
        .filter(([id]) => !removalArr.includes(id))
        .map(([id, searchHit]) => {
          const item = feedItems.find((fi) => fi.id === id);
          return {
            ...(item ?? {}),
            searchHit,
          } satisfies Partial<FeedWithAugTypes>;
        })
    : filterOutRemovedItems(
        feedItems ?? [],
        (item) => !removalArr.includes(item.id ?? -1),
      );

  const finalFilteredItems = feedVolumeSelectedDate
    ? filteredOrSearchedItems.filter((item) => {
        return isWithinRange(
          item.articleLastUpdateDate,
          feedVolumeSelectedDate.startDate,
          feedVolumeSelectedDate.endDate,
        );
      })
    : filteredOrSearchedItems;

  // Return sorted items
  return finalFilteredItems.sort(sortItems);
});

const deriveFilterGroups = derive<
  {
    key: string;
    title: string;
    filters: DerivedFilterItem[];
    sorted: boolean;
  }[]
>((get) => {
  const { categoryAppliedFilters, removalArr, filterFeedIdSearch } =
    get(useFeedStore);
  if (!categoryAppliedFilters) return [];
  const filterIds = filterFeedIdSearch.map(([id]) => id);

  return Object.entries(categoryAppliedFilters).map(
    ([categoryKey, categoryValue]) => {
      let filters = Object.entries(categoryValue?.itemCounts ?? {}).map(
        ([filter, count]) => ({
          id: categoryKey + filter,
          value: filter,
          totalCount: count.length,
          count: count.filter(
            (id) => !removalArr.includes(id) && filterIds.includes(id),
          ).length,
          selected: categoryValue.selected?.includes(filter) ?? false,
        }),
      );

      // Sort the Social Engagement filters specifically
      if (categoryKey === "Social Engagement") {
        filters = filters.sort((a, b) => {
          if (a?.value === "0") return 1;
          if (b?.value === "0") return -1;
          const aValue = Number.parseInt(
            a?.value?.split("-")[0]?.replace(",", "") ?? "0",
          );
          const bValue = Number.parseInt(
            b?.value?.split("-")[0]?.replace(",", "") ?? "0",
          );
          return bValue - aValue;
        });
      }

      return {
        key: categoryKey,
        title: categoryKey,
        filters,
        sorted: categoryValue.sorted ?? true,
      };
    },
  );
});

// derive feedName
const deriveFeedName = derive<string>((get) => {
  const { feedId, feedSidebarData } = get(useFeedStore);
  const currentFeed = feedSidebarData.find((f) => +f?.id === feedId);
  return currentFeed ? (currentFeed.name ?? "") : "";
});

// derive feedTerms
const deriveFeedTerms = derive<FeedDataTermsType[]>((get) => {
  const { feedId, feedSidebarData } = get(useFeedStore);
  const currentFeed = feedSidebarData.find((f) => +f?.id === feedId);
  return currentFeed ? (currentFeed.terms ?? []) : [];
});

// derive knownTags
const deriveKnownTags = derive<string[]>((get) => {
  const { feedId, feedSidebarData } = get(useFeedStore);
  const currentFeed = feedSidebarData.find((f) => +f?.id === feedId);
  return currentFeed
    ? (currentFeed.knownTags?.map((tag) => tag.tag) ?? [])
    : [];
});

interface FeedPercentages {
  feedVolumePercentage: number | null;
  feedPercentage: number | null;
}
/**
 * Derives feed-related percentages based on the current feed state.
 * @param {function} get - Function to retrieve state slices from the store.
 * @returns {FeedPercentages} An object containing:
 *   - `feedVolumePercentage`: Percentage of feed volume relative to total stories.
 *   - `feedPercentage`: Percentage of filtered stories relative to feed volume (or total stories as a fallback).
 */
const deriveFeedPercentage = derive<FeedPercentages>((get) => {
  const { feedItems, filterFeedIdSearch, removalArr, feedVolume } =
    get(useFeedStore);

  if (!feedItems) {
    return {
      feedVolumePercentage: null,
      feedPercentage: null,
    };
  }

  const totalStories = feedItems.length - removalArr.length;
  const filteredStoryCount = filterFeedIdSearch.filter(
    ([id]) => !removalArr.includes(id),
  ).length;

  const feedVolumePercentage = feedVolume
    ? Math.round((feedVolume / totalStories) * 100)
    : null;

  const feedPercentage = feedVolume
    ? Math.round((filteredStoryCount / feedVolume) * 100)
    : Math.round((filteredStoryCount / totalStories) * 100);

  return {
    feedVolumePercentage,
    feedPercentage,
  };
});

const deriveArticleIds = derive<number[]>((get) => {
  const { feedItems } = get(useFeedStore);
  return feedItems
    .map((item) => item.id)
    .filter((id): id is number => id !== undefined);
});

const deriveFilteredItemsLength = derive<number>((get) => {
  const filteredItems = get(deriveFilteredFeedItems);
  return filteredItems.length;
});

const useIsFilterOrSearch = () => {
  return useStore(deriveFilterOrSearch);
};

const useFeedPercentage = () => {
  return useStore(deriveFeedPercentage);
};

// Define hooks for each derived state
const useFeedName = () => {
  return useStore(deriveFeedName);
};

const useFeedTerms = () => {
  return useStore(deriveFeedTerms);
};

const useKnownTags = () => {
  return useStore(deriveKnownTags);
};

// const useStoryCount = () => {
//   return useStore(deriveStoryCount);
// };

const useArticleCount = () => {
  return useStore(deriveArticleCount);
};

const useFilteredFeedItems = () => {
  return useStore(deriveFilteredFeedItems);
};

const useFilterGroups = () => {
  return useStore(deriveFilterGroups);
};

const useArticleIds = () => {
  return useStore(deriveArticleIds);
};

const useFilteredItemsLength = () => {
  return useStore(deriveFilteredItemsLength);
};

// Export hooks

export {
  useIsFilterOrSearch,
  useFeedPercentage,
  useFeedName,
  useFeedTerms,
  useKnownTags,
  // useStoryCount,
  useArticleCount,
  useFilteredFeedItems,
  useFilterGroups,
  useArticleIds,
  useFilteredItemsLength,
};
