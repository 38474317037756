import { transformSummary } from "@/components/news/shared/utils";
import { useState } from "react";
import EditableField from "../shared/EditableField";

interface ArticleHeaderProps {
  summary: string;
  overriddenSummary?: string;
  onSummaryChange?: (newSummary: string) => void;
}

const FeedArticleSummary: React.FC<ArticleHeaderProps> = ({
  summary,
  overriddenSummary,
  onSummaryChange,
}) => {
  const [editedSummary, setEditedSummary] = useState(
    (overriddenSummary || summary || "Add a summary...").trim(),
  );

  const handleSummarySave = (editedFieldName: string) => {
    if (editedFieldName.trim().length < 1) {
      return false;
    }

    onSummaryChange?.(editedFieldName);
    setEditedSummary(editedFieldName);
    return true;
  };

  return (
    <div className="flex gap-1 flex-col relative -mb-0.5">
      <b className="uppercase leading-4">Summary</b>
      <EditableField
        fieldName="Summary"
        initialFieldValue={editedSummary}
        onSave={handleSummarySave}
        maxLines={3}
        className="text-left overflow-hidden leading-tight w-full text-xs pr-5 text-gray-800"
      >
        <TransformedSummary summary={editedSummary || "Add a summary..."} />
      </EditableField>
    </div>
  );
};

interface TransformedSummaryProps {
  summary: string;
  onSummaryChange?: (newSummary: string) => void;
}

const TransformedSummary: React.FC<TransformedSummaryProps> = ({ summary }) => {
  const transformedSummary = transformSummary(summary ?? "");
  return (
    <>
      {transformedSummary.map((paragraph, index) => (
        <p
          key={`${index}-${paragraph}`}
          className="line-clamp-4 mt-0 break-words"
          title={summary}
        >
          {paragraph}
        </p>
      ))}
    </>
  );
};

export default FeedArticleSummary;
