import { Button } from "@/components/ui/button";
import { useAuthForm } from "@/lib/auth/useAuthForm";
import { cn } from "@/lib/utils";
import { useState } from "react";
import type { Path } from "react-hook-form";
import type { z } from "zod";
import { Label } from "../ui/field";
import { Input } from "../ui/textfield";

type AuthFormProps<TSchema extends z.ZodType<any, any, any>> = {
  schema: TSchema;
  onSubmit: (data: z.infer<TSchema>) => Promise<void>;
  fields: {
    name: Path<z.infer<TSchema>>;
    label: string;
    type: string;
  }[];
  submitLabel?: string;
};

export function AuthForm<TSchema extends z.ZodType<any, any, any>>({
  schema,
  onSubmit,
  fields,
  submitLabel = "Submit",
}: AuthFormProps<TSchema>) {
  const [showPassword, setShowPassword] = useState(false);
  const {
    form,
    onSubmit: handleSubmit,
    isLoading,
    error,
    formState,
  } = useAuthForm(schema, onSubmit);

  return (
    <form onSubmit={handleSubmit} noValidate>
      {fields.map((field) => (
        <div key={field.name} className="mb-2">
          <Label
            htmlFor={field.name}
            className="block text-sm font-medium text-gray-700"
          >
            {field.label}
          </Label>
          <div className="mt-1 relative">
            <Input
              id={field.name}
              type={
                field.type === "password"
                  ? showPassword
                    ? "text"
                    : "password"
                  : field.type
              }
              {...form.register(field.name)}
              className={cn(
                field.type === "password" && "pr-12",
                formState.errors[field.name] &&
                  "border-red-600 ring-red-600 focus:!ring-red-600 focus:!border-red-600",
              )}
            />
            {field.type === "password" && (
              <Button
                type="button"
                variant={"ghost"}
                onPress={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-700 font-md"
              >
                {showPassword ? "Hide" : "Show"}
              </Button>
            )}
          </div>
          <div className="min-h-[20px]">
            {formState.errors[field.name] && (
              <p className="mt-2 text-sm text-red-600">
                {formState.errors[field.name]?.message as string}
              </p>
            )}
          </div>
        </div>
      ))}

      {error && <div className="text-red-600 text-sm mt-2">{error}</div>}

      <Button type="submit" isDisabled={isLoading} className="w-full mt-5">
        {isLoading ? "Submitting..." : submitLabel}
      </Button>
    </form>
  );
}
