import { Button } from "@/components/ui/button";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
} from "@/components/ui/dialog";
import { FieldError, Label } from "@/components/ui/field";
import { Input, TextField } from "@/components/ui/textfield";
import toast from "@/components/ui/toast";
import { cn } from "@/lib/utils";
import useFeedStore from "@/store/useFeedStore";
import { CircleNotch, X } from "@phosphor-icons/react";
import { type ChangeEvent, useCallback, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { toast as sonnerToast } from "sonner";

const MODAL_PARAM = "dialog" as const;

type Props = {
  open: boolean;
  onClose: () => void;
};

const AddArticleURLModal = (props: Props) => {
  const { open, onClose } = props;
  const location = useLocation();
  const [inputValue, setInputValue] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [touched, setTouched] = useState(false);
  const [addArticle, removeArticles] = useFeedStore((state) => [
    state.addArticle,
    state.removeArticles,
  ]);
  const [_, setSearchParams] = useSearchParams();
  const [isInAdding, setIsInAdding] = useState(false);
  const { feedId } = useParams();
  const navigate = useNavigate();

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setInputValue(newValue);
      if (invalid) setInvalid(false); // Reset invalid state on change
    },
    [invalid],
  );

  const validateUrl = (value: string) => {
    const urlRegex =
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}(\/\S*)?$/;
    return urlRegex.test(value);
  };

  const onAddArticle = async (closeFn: () => void) => {
    if (!inputValue || !validateUrl(inputValue)) {
      setInvalid(true);
      setTouched(true); // Mark as touched on submit
      return;
    }

    setIsInAdding(true);
    const data = await addArticle(inputValue);
    setIsInAdding(false);
    const { uiMessage, feedArticle } = data;
    const addedArticleId = feedArticle?.id;
    if (uiMessage?.type === "SUCCESS" && addedArticleId) {
      const isAlreadyInNewsfeed = uiMessage.message.includes("already");
      const messageTitle = isAlreadyInNewsfeed
        ? "Article already in newsfeed"
        : "Added to newsfeed";

      const messageDescription =
        uiMessage.message ||
        (isAlreadyInNewsfeed
          ? "This article is already in your newsfeed."
          : "New article successfully added to newsfeed.");

      toast({
        type: isAlreadyInNewsfeed ? "handraise" : "success",
        title: messageTitle,
        description: (
          <div>
            <p className="text-xs font-normal mb-0">{messageDescription}</p>
            <Button
              size="none"
              variant="link"
              className="underline text-xs font-normal"
              onPress={() => handleUndo(addedArticleId)}
            >
              Undo
            </Button>
          </div>
        ),
      });

      closeFn();
      if (location.pathname.includes("article-preview"))
        // Already in article-preview page, hard refresh
        window.location.href = `/newsfeeds/${feedId}/article-preview/${addedArticleId}/?from=add-article-url`;
      else
        navigate(
          `/newsfeeds/${feedId}/article-preview/${addedArticleId}/?from=add-article-url`,
        );
      return;
    }
    if (uiMessage?.type === "FAIL") {
      toast({
        type: "error",
        title: "Unable to add article",
        description: uiMessage.message,
      });
    }
    closeFn();
  };

  const handleUndo = (articleId: number) => {
    removeArticles([articleId]);
    sonnerToast.dismiss();
  };

  const handleCloseModal = () => {
    onClose();
    setInputValue("");
    setInvalid(false);
    setTouched(false);
    setIsInAdding(false);
    setSearchParams(
      (prev) => {
        prev.delete(MODAL_PARAM);
        return prev;
      },
      { replace: true },
    );
  };

  return (
    <DialogOverlay
      isOpen={open}
      onOpenChange={(isOpen) => !isOpen && handleCloseModal()}
    >
      <DialogContent className="min-w-[425px] w-[25vw] max-w-[800px]">
        {({ close }) => (
          <>
            <DialogHeader>
              <DialogTitle>Add an article</DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 py-4 relative">
              <TextField
                autoFocus
                validate={(value) =>
                  validateUrl(value) ? null : "Invalid link"
                }
                onBlur={() => setTouched(true)}
              >
                <Label>URL/Link*</Label>
                <Input
                  value={inputValue}
                  className={cn(
                    "pr-8",
                    touched && invalid && "border-rose-500",
                  )}
                  onChange={handleInputChange}
                  placeholder="https://example.com"
                />
                <FieldError />
                {inputValue?.length > 0 && (
                  <span
                    className="absolute top-14 right-3 w-4 h-4 bg-slate-300 rounded-full p-0.5 cursor-pointer"
                    onClick={() => setInputValue("")}
                  >
                    <X className="text-white w-3 h-3" />
                  </span>
                )}
              </TextField>
            </div>
            <DialogFooter className="">
              <div className="flex w-full sm:flex-row-reverse gap-3">
                <Button
                  onPress={() => onAddArticle(close)}
                  type="submit"
                  isDisabled={isInAdding}
                >
                  {isInAdding ? (
                    <>
                      <span className="mr-2">
                        <CircleNotch className="animate-spin" />
                      </span>
                      <span>Adding</span>
                    </>
                  ) : (
                    <span>Add Article</span>
                  )}
                </Button>
                <Button onPress={handleCloseModal} variant="outline">
                  <span>Cancel</span>
                </Button>
              </div>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </DialogOverlay>
  );
};

export default AddArticleURLModal;
