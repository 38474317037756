export const isProduction = import.meta.env.VITE_ENVIRONMENT === "production";
export const isLocal =
  import.meta.env.VITE_ENVIRONMENT === "local" ||
  import.meta.env.VITE_ENVIRONMENT === "development";
export const isDevelopment = ["local", "stage", "development"].includes(
  import.meta.env.VITE_ENVIRONMENT,
);

export const SMALL_WINDOW_WIDTH = 640;
export const MEDIUM_WINDOW_WIDTH = 768;
