import { Button } from "@/components/ui/controls/button";
import { SheetContent, SheetTitle } from "@/components/ui/controls/sheet";
import { Switch } from "@/components/ui/controls/switch";
import { Skeleton } from "@/components/ui/feedback/skeleton";
import { Input } from "@/components/ui/forms/input";
import PublisherSearchDropdown from "@/components/ui/molecules/PublisherSearchDropdown";
import { toast } from "@/components/ui/toast";
import { readFragment } from "@/data-access/graphql";
import {
  type NewsFeedPublisherType,
  NewsFeedPublishers,
  type NewsFeedPublishersDataType,
} from "@/data-access/news/newsFeeds";
import {
  CREATE_PUBLISHER_CONFIGURATION,
  CREATE_TIER_CONFIGURATION,
  PUBLISHER_CONFIGURATION_QUERY,
  type PublisherConfigurationType,
  READERSHIP_AND_DOMAIN_QUERY,
  type ReadershipConfigurationType,
  TOGGLE_DOMAIN_OR_READERSHIP,
  type TierConfigurationType,
  UPDATE_PUBLISHER_CONFIGURATION,
  UPDATE_TIER_CONFIGURATION,
  UPSERT_READERSHIP_CONFIGURATION,
  tierConfigurationFragment,
  tierFragment,
} from "@/data-access/news/tier";
import usePublishersStore from "@/store/usePublishersStore";
import { Trash } from "@phosphor-icons/react";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "urql";

type AddedPublications = Record<string, NewsFeedPublisherType[]>;

const TABS = {
  CUSTOM: "all",
  TIER1: "tier1",
  TIER2: "tier2",
  TIER3: "tier3",
};

const NewsFeedPublisherPanel = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCount, setSearchCount] = useState(50);

  const [domainAuthority, setDomainAuthority] = useState(false);
  const [readership, setReadership] = useState(false);
  const [addedPublications, setAddedPublications] = useState<AddedPublications>(
    {
      [TABS.CUSTOM]: [],
      [TABS.TIER1]: [],
      [TABS.TIER2]: [],
      [TABS.TIER3]: [],
    },
  );

  const [publishers, setPublishers] = useState<NewsFeedPublishersDataType>([]);

  const [{ data: publisherData, fetching: loadingPublishers }] = useQuery({
    query: NewsFeedPublishers,
    variables: {
      pubLimit: searchCount,
      pubFilter: searchTerm,
    },
  });

  const addPublisherToSearch = (publishers: NewsFeedPublisherType[]) => {
    setPublishers((prevPublishers) =>
      [...prevPublishers, ...publishers].reduce((acc, publisher) => {
        if (!acc.find((p) => p.id === publisher.id)) {
          acc.push(publisher);
        }
        return acc;
      }, [] as NewsFeedPublishersDataType),
    );
  };

  useEffect(() => {
    const publishers = publisherData?.publishers;
    if (!publishers) return;
    addPublisherToSearch(publishers);
  }, [publisherData]);

  const [selectedTab, setDisplayPanel] = usePublishersStore((state) => [
    state.selectedTab,
    state.setDisplayPanel,
  ]);

  const [tier1Min, setTier1Min] = useState<string>("");
  const [tier2Min, setTier2Min] = useState<string>("");
  const [tier1ReadershipMin, setTier1ReadershipMin] =
    useState<string>("1000000");
  const [tier2ReadershipMin, setTier2ReadershipMin] =
    useState<string>("500000");
  const [selectedTiersTab, setSelectedTiersTab] = useState(selectedTab);
  const [isSaving, setIsSaving] = useState(false);

  const [, createTierConfiguration] = useMutation(CREATE_TIER_CONFIGURATION);
  const [, updateTierConfiguration] = useMutation(UPDATE_TIER_CONFIGURATION);
  const [, createPublisherConfiguration] = useMutation(
    CREATE_PUBLISHER_CONFIGURATION,
  );
  const [, updatePublisherConfiguration] = useMutation(
    UPDATE_PUBLISHER_CONFIGURATION,
  );
  const [, toggleReadershipOrDomain] = useMutation(TOGGLE_DOMAIN_OR_READERSHIP);
  const [, upsertReadershipConfiguration] = useMutation(
    UPSERT_READERSHIP_CONFIGURATION,
  );

  const [hasTierData, setHasTierData] = useState(false);
  const [hasReadershipData, setHasReadershipData] = useState(false);
  const [hasTierConfiguration, setHasTierConfiguration] = useState(false);

  const [
    { data: readershipAndDomainData, fetching: fetchingReadershipDomain },
  ] = useQuery({
    query: READERSHIP_AND_DOMAIN_QUERY,
  });

  useEffect(() => {
    if (!readershipAndDomainData) return;

    const { readershipConfiguration, tierConfiguration } =
      readershipAndDomainData;

    handleConfigurationData(readershipConfiguration, true);
    handleConfigurationData(tierConfiguration, false);

    if (readershipConfiguration?.tenant.enableReadership) setReadership(true);
    if (tierConfiguration?.tenant.enableCustomTierScoring)
      setDomainAuthority(true);
  }, [readershipAndDomainData]);

  const handleConfigurationData = (
    configuration: TierConfigurationType | ReadershipConfigurationType,
    isReadership: boolean,
  ) => {
    const { tiers } = readFragment(tierConfigurationFragment, configuration);
    const tiersData = readFragment(tierFragment, tiers);

    if (!tiersData) return false;

    const tier1 = tiersData.find((tier) => tier.tier === 1);
    const tier2 = tiersData.find((tier) => tier.tier === 2);

    if (tier1) {
      if (isReadership) {
        setTier1ReadershipMin(tier1.lowerBound.toString());
      } else {
        setTier1Min(tier1.lowerBound.toString());
      }
    }

    if (tier2) {
      if (isReadership) {
        setTier2ReadershipMin(tier2.lowerBound.toString());
      } else {
        setTier2Min(tier2.lowerBound.toString());
      }
    }

    if (isReadership) {
      setHasReadershipData(true);
    } else {
      setHasTierData(true);
    }
  };
  useEffect(() => {
    setSelectedTiersTab(selectedTab);
  }, [selectedTab]);

  const usePublisherConfigurationQuery = (
    tier: "TIER_ONE" | "TIER_TWO" | "TIER_THREE",
  ) => {
    return useQuery({
      query: PUBLISHER_CONFIGURATION_QUERY,
      variables: { tier },
      requestPolicy: "network-only",
      context: useMemo(
        () => ({
          additionalTypenames: undefined,
        }),
        [],
      ),
    });
  };

  const [{ data: tierOneResult }] = usePublisherConfigurationQuery("TIER_ONE");
  const [{ data: tierTwoResult }] = usePublisherConfigurationQuery("TIER_TWO");
  const [{ data: tierThreeResult }] =
    usePublisherConfigurationQuery("TIER_THREE");

  const getFirstPublisherLogo = (publisher: NewsFeedPublishersDataType[0]) => {
    const logos = publisher.logos.filter((logo) => logo.image?.url);
    return logos?.[0]?.image?.url ?? "";
  };

  const TABS_MAP = {
    TIER_ONE: "tier1",
    TIER_TWO: "tier2",
    TIER_THREE: "tier3",
  };

  const getTab = (tier: string) => TABS_MAP[tier as keyof typeof TABS_MAP];

  const handleQueryResult = (
    tier: string,
    publisherResponse?: PublisherConfigurationType,
  ) => {
    const tab = getTab(tier);
    if (!publisherResponse || !tab) return;

    try {
      const publishers = publisherResponse.publisherConfiguration?.publishers;

      if (!publishers) return;

      setAddedPublications((prev: AddedPublications) => ({
        ...prev,
        [tab]: publishers,
      }));

      setHasTierConfiguration(true);
    } catch (error) {
      console.error("Handling query result caused an error:", error);
      setAddedPublications((prev: AddedPublications) => ({
        ...prev,
        [tab]: [],
      }));
      setHasTierConfiguration(false);
    }
  };

  const handleTierResult = (
    tier: string,
    result: PublisherConfigurationType,
  ) => {
    handleQueryResult(tier, result);
    const publishers = result?.publisherConfiguration?.publishers;
    if (!publishers) return;
    addPublisherToSearch(publishers);
  };

  useEffect(() => handleTierResult("TIER_ONE", tierOneResult), [tierOneResult]);
  useEffect(() => handleTierResult("TIER_TWO", tierTwoResult), [tierTwoResult]);
  useEffect(
    () => handleTierResult("TIER_THREE", tierThreeResult),
    [tierThreeResult],
  );

  const handleSelectPublisher = (id: string) => {
    setAddedPublications((prev: AddedPublications) => {
      const publisher = publishers.find((p) => p.id === id);
      if (!publisher) throw new Error("Publisher not found");

      const updatedPublications = Object.fromEntries(
        Object.entries(prev).map(([tab, publications]) => {
          const newPublications = publications.filter((p) => p.id !== id);

          if (tab === selectedTiersTab) {
            const isAlreadyAdded = publications.some((p) => p.id === id);
            if (isAlreadyAdded) {
              return [tab, newPublications];
            }
            return [tab, [...newPublications, publisher]];
          }
          return [tab, newPublications];
        }),
      );

      return updatedPublications;
    });
  };

  const onRemoveSelected = (publisher: NewsFeedPublisherType) => {
    setAddedPublications((prevAdded) => ({
      ...prevAdded,
      [selectedTiersTab]: (prevAdded[selectedTiersTab] || []).filter(
        (p) => p.id !== publisher.id,
      ),
    }));
  };

  const onSavePublicationTierSettings = async () => {
    setIsSaving(true);
    const tierConfigurationInput = {
      input: {
        tierOne: {
          lowerBound: Number(tier1Min),
          upperBound: 100,
        },
        tierTwo: {
          lowerBound: Number(tier2Min),
          upperBound: Number(tier1Min),
        },
        tierThree: {
          lowerBound: 0,
          upperBound: Number(tier2Min),
        },
      },
    };

    if (domainAuthority) {
      if (hasTierData) {
        const updateTierConfigurationResponse = await updateTierConfiguration(
          tierConfigurationInput,
        );

        if (updateTierConfigurationResponse.error) {
          toast.error("Failed to update domain authority settings");
          setIsSaving(false);
          return;
        }
      } else {
        const createTierConfigurationResponse = await createTierConfiguration(
          tierConfigurationInput,
        );

        if (createTierConfigurationResponse.error) {
          toast.error("Failed to save domain authority settings");
          setIsSaving(false);
          return;
        }
      }
    }

    if (readership) {
      if (hasReadershipData) {
        const readershipInput = {
          tierOne: {
            lowerBound: Number(tier1ReadershipMin),
            upperBound: Number(tier1ReadershipMin),
          },
          tierTwo: {
            lowerBound: Number(tier2ReadershipMin),
            upperBound: Number(tier1ReadershipMin),
          },
          tierThree: {
            lowerBound: 0,
            upperBound: Number(tier2ReadershipMin),
          },
        };
        const upsertReadershipConfigurationResponse =
          await upsertReadershipConfiguration({ input: readershipInput });
        if (upsertReadershipConfigurationResponse.error) {
          toast.error("Failed to save readership settings");
          setIsSaving(false);
          return;
        }
      }
    }

    toggleReadershipOrDomain({
      domain: domainAuthority,
      readership,
    }).then((response) => {
      if (response.error) {
        toast.error("Failed to save readership or domain authority settings");
      }
      setIsSaving(false);
    });

    if (addedPublications) {
      const tierMapping: Record<
        number,
        "TIER_ONE" | "TIER_TWO" | "TIER_THREE"
      > = {
        0: "TIER_ONE",
        1: "TIER_TWO",
        2: "TIER_THREE",
      };

      const promises = Object.entries(addedPublications)
        .map(([tab, publications]) => {
          const tierIndex = Number.parseInt(tab.replace("tier", "")) - 1;
          const tierName = tierMapping[tierIndex];
          if (!tierName) return;

          const publishers = publications?.map((p) => Number(p.id)) || [];

          if (hasTierConfiguration) {
            return updatePublisherConfiguration({
              input: {
                tier: tierName,
                publishers,
              },
            });
          }
          return createPublisherConfiguration({
            input: {
              tier: tierName,
              publishers,
            },
          });
        })
        // biome-ignore lint/suspicious/noExplicitAny: <regression>
        .filter((promise): promise is Promise<any> => Boolean(promise));

      const updateResponses = await Promise.all(promises);
      if (updateResponses.some((res) => res.error)) {
        toast.error("Failed to save publisher settings");
        setIsSaving(false);
        return;
      }
    }

    setIsSaving(false);
    toast.success("Domain authority settings saved successfully");
    handleClosePanel();
  };

  const renderPublisherIcon = (publisher: NewsFeedPublisherType) => {
    const logo = getFirstPublisherLogo(publisher);
    const iconContent = logo ? (
      <img
        src={logo}
        alt={publisher.name}
        className="max-h-8 max-w-16 object-contain"
      />
    ) : (
      <div className="h-6 w-6 flex items-center justify-center bg-slate-200 rounded-full">
        {publisher.name.charAt(0).toUpperCase()}
      </div>
    );

    return <div className="flex justify-center w-16 mr-2">{iconContent}</div>;
  };

  const startSearch = (value: string) => {
    setSearchCount(value ? 10 : 50);
    setSearchTerm(value);
  };

  const renderSelectedPublications = () => (
    <>
      <div className="text-sm mt-4">
        All Target Publications{" "}
        <span className="text-sm text-slate-500 mt-2">
          ({addedPublications[selectedTiersTab]?.length ?? 0} publications)
        </span>
      </div>
      {addedPublications[selectedTiersTab]?.length === 0 && (
        <div className="text-sm text-slate-400 mt-2">
          No target publications added
        </div>
      )}
      <div className="mt-2 w-full">
        {addedPublications[selectedTiersTab]?.map(
          (publisher: NewsFeedPublisherType) => (
            <div
              key={publisher?.id}
              className="flex items-center gap-2 py-2 hover:bg-slate-100 w-full"
              style={{ cursor: "pointer" }}
            >
              <div className="w-16">{renderPublisherIcon(publisher)}</div>
              <div className="flex flex-col flex-grow w-56">
                <span className="text-sm truncate">{publisher?.name}</span>
                <span className="text-xs text-slate-500">{publisher.url}</span>
              </div>
              <div className="block float-right flex-shrink-0">
                <Button
                  variant="ghost"
                  size="inline"
                  onClick={() => onRemoveSelected(publisher)}
                >
                  <Trash size={18} color="#6E7D93" />
                </Button>
              </div>
            </div>
          ),
        )}
      </div>
    </>
  );

  const handleClosePanel = () => {
    setDisplayPanel(false);
  };

  const handleTier1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    const tier2MinNumber = +tier2Min;
    if (value >= tier2MinNumber) {
      setTier1Min(value.toString());
    } else {
      return false;
    }
  };

  const handleTier2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    const tier1MinNumber = +tier1Min;
    if (value <= tier1MinNumber) {
      setTier2Min(value.toString());
    } else {
      return false;
    }
  };

  const handleReadership1MinChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = +e.target.value;
    const tier2ReadershipMinNumber = +tier2ReadershipMin;
    if (value >= tier2ReadershipMinNumber) {
      setTier1ReadershipMin(value.toString());
    } else {
      return false;
    }
  };

  const handleReadership2MinChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = +e.target.value;
    const tier1ReadershipMinNumber = +tier1ReadershipMin;
    if (value <= tier1ReadershipMinNumber) {
      setTier2ReadershipMin(value.toString());
    } else {
      return false;
    }
  };

  const handleReadershipToggle = () => {
    setReadership((prev) => !prev);
    if (!readership) {
      setDomainAuthority(false);
    }
  };

  const handleDomainAuthorityToggle = () => {
    setDomainAuthority((prev) => !prev);
    if (!domainAuthority) {
      setReadership(false);
    }
  };

  return (
    <SheetContent hasClose={false} className="p-5 max-w-md overflow-auto pt-0">
      <SheetTitle className="sticky z-10 top-0 bg-white">
        <div className="flex flex-row justify-between items-center py-4">
          <h3>Tier Settings</h3>
          <div className="flex gap-2">
            <Button variant="outline" onClick={handleClosePanel}>
              Cancel
            </Button>
            <Button
              variant="default"
              disabled={isSaving}
              onClick={onSavePublicationTierSettings}
            >
              Save
            </Button>
          </div>
        </div>
        <div className="h-px w-full bg-slate-100" />
      </SheetTitle>

      <div className="flex flex-col gap-8 pt-4">
        <div className="flex justify-between items-center">
          <span>Domain Authority</span>
          {fetchingReadershipDomain ? (
            <Skeleton className="w-11 h-6 bg-violet-900/10 rounded-full" />
          ) : (
            <Switch
              checked={domainAuthority}
              onCheckedChange={handleDomainAuthorityToggle}
            />
          )}
        </div>
        {domainAuthority && (
          <div className="flex flex-col gap-4 text-xs">
            <span className="text-slate-500">Select ranges from 0 to 100</span>
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-2">
                <span className="w-14">Tier 1 &nbsp;&#62;&#61;</span>
                <Input
                  type="number"
                  value={+tier1Min}
                  onChange={handleTier1Change}
                  className="w-12 h-7 pr-0"
                />
              </div>
              <div className="flex items-center gap-2">
                <span className="w-14">Tier 2 &gt;=</span>
                <Input
                  type="number"
                  value={+tier2Min}
                  onChange={handleTier2Change}
                  className="w-12 h-7 pr-0"
                />
                <span>&lt;&nbsp;</span>
                <span>{+tier1Min}</span>
              </div>
              <div className="flex items-center gap-2">
                <span className="w-14">Tier 3 &lt;&nbsp;</span>
                <span>{+tier2Min}</span>
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-between items-center">
          <span>Readership</span>
          {fetchingReadershipDomain ? (
            <Skeleton className="w-11 h-6 bg-violet-900/10 rounded-full" />
          ) : (
            <Switch
              checked={readership}
              onCheckedChange={handleReadershipToggle}
            />
          )}
        </div>
        {readership && (
          <div className="flex flex-col gap-4 text-xs">
            <span className="text-slate-500">Select ranges for Readership</span>
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-2">
                <span className="w-14">Tier 1 &nbsp;&#62;&#61;</span>
                <Input
                  type="number"
                  value={+tier1ReadershipMin}
                  onChange={handleReadership1MinChange}
                  className="w-24 h-7 pr-0"
                />
              </div>
              <div className="flex items-center gap-2">
                <span className="w-14">Tier 2 &gt;=</span>
                <Input
                  type="number"
                  value={+tier2ReadershipMin}
                  onChange={handleReadership2MinChange}
                  className="w-24 h-7 pr-0"
                />
                <span>&lt;&nbsp;</span>
                <span>{+tier1ReadershipMin}</span>
              </div>
              <div className="flex items-center gap-2">
                <span className="w-14">Tier 3 &lt;&nbsp;</span>
                <span>{+tier2ReadershipMin}</span>
              </div>
            </div>
          </div>
        )}
        <div className="h-px bg-slate-100" />
        <div>
          <h4 className="text-sm font-semibold mb-2 mt-0">
            Target publications
          </h4>
          <div className="flex items-center gap-4 mb-4">
            <span
              className={`py-1 text-xs px-2 border border-slate-200 rounded-md cursor-pointer ${
                selectedTiersTab === TABS.TIER1 ? "bg-slate-100" : ""
              }`}
              onClick={() => setSelectedTiersTab(TABS.TIER1)}
              onKeyUp={() => setSelectedTiersTab(TABS.TIER1)}
            >
              Tier 1
            </span>
            <span
              className={`py-1 text-xs px-2 border border-slate-200 rounded-md cursor-pointer ${
                selectedTiersTab === TABS.TIER2 ? "bg-slate-100" : ""
              }`}
              onClick={() => setSelectedTiersTab(TABS.TIER2)}
              onKeyUp={() => setSelectedTiersTab(TABS.TIER2)}
            >
              Tier 2
            </span>
            <span
              className={`py-1 text-xs px-2 border border-slate-200 rounded-md cursor-pointer ${
                selectedTiersTab === TABS.TIER3 ? "bg-slate-100" : ""
              }`}
              onClick={() => setSelectedTiersTab(TABS.TIER3)}
              onKeyUp={() => setSelectedTiersTab(TABS.TIER3)}
            >
              Tier 3
            </span>
          </div>
          <PublisherSearchDropdown
            publishers={publishers}
            isLoading={loadingPublishers}
            onSearch={startSearch}
            onSelect={handleSelectPublisher}
            selectedPublishers={addedPublications}
          />
          {renderSelectedPublications()}
        </div>
      </div>
    </SheetContent>
  );
};

export default NewsFeedPublisherPanel;
