import { zodResolver } from "@hookform/resolvers/zod";
import { ChartLineUp, Eye, SealQuestion } from "@phosphor-icons/react";
import { type FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import type { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/forms/form";
import { Input } from "@/components/ui/forms/input";
import { RadioGroup } from "@/components/ui/forms/radio-group";
import { RadioJumboGroupItem } from "@/components/ui/forms/radio-jumbo-group-item";

import FacebookIcon from "@/assets/svg/facebook.svg?react";
import InstagramIcon from "@/assets/svg/instagram.svg?react";
import LinkedInIcon from "@/assets/svg/linkedin.svg?react";

import { useFeature } from "@/lib/feature-management";
import useCampaignCreationEffects from "@/pages/campaigns/hooks/useCampaignCreationEffects";
import { generateArticle } from "@/store/actions/builder";
import type {
  Campaign,
  CampaignTypeEnum,
} from "@/store/generated-apis/campaignApi";
import { selectLoadingArticle } from "@/store/selectors/builderSelector";
import { Button } from "../ui/controls/button";
import {
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/feedback/dialog";
import BuilderLoader from "./builderLoader";
import { createFormSchema } from "./createFormSchema";

const platformOptions = [
  {
    value: "linkedin",
    label: "LinkedIn",
    icon: <LinkedInIcon />,
    helpText: "Single image ad (1:1)",
  },
  {
    value: "facebook",
    label: "Facebook",
    icon: <FacebookIcon />,
    helpText: "Coming soon!",
    disabled: true,
  },
  {
    value: "instagram",
    label: "Instagram",
    icon: <InstagramIcon />,
    helpText: "Coming soon!",
    disabled: true,
  },
];

const goalOptions = [
  {
    value: "awareness",
    label: "Awareness",
    icon: <Eye size="16" />,
    helpText: "Extend your article’s reach",
  },
  {
    value: "traffic",
    label: "Drive Traffic",
    icon: <ChartLineUp size="16" />,
    helpText: "Drive viewers to a page",
  },
  {
    value: "unclear",
    label: "I don't know",
    icon: <SealQuestion size="16" />,
    helpText: "I'll figure it out later",
  },
];

type CreateCampaignDialogProps = {
  url?: string;
};

const CreateCampaignDialog: FC<CreateCampaignDialogProps> = ({ url }) => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const submitting = useSelector(selectLoadingArticle);
  const [submitted, setSubmitted] = useState(false);
  const showSupportGoals = useFeature("support-goals", false);
  const adBuilderRedesign = useFeature("ad-builder-redesign", false);
  const [articleUrl, setArticleUrl] = useState(url || "");
  const form = useForm<z.infer<typeof createFormSchema>>({
    resolver: zodResolver(createFormSchema),
    defaultValues: {
      name: "",
      url,
      channel: "linkedin",
      goal: "",
    },
  });

  const [createCampaign, isLoadingCreate] = useCampaignCreationEffects({
    form,
    articleUrl,
    submitted,
  });

  const onEnterArticleUrl = () => form.clearErrors("url");

  const onBlurArticleUrl = () => {
    const url = form.getValues("url");
    const { invalid } = form.getFieldState("url");
    if (!adBuilderRedesign || invalid || !url) return;
    setArticleUrl(url);
  };

  const renderUrlInput = () => (
    <FormField
      control={form.control}
      name="url"
      render={({ field }) => (
        <FormItem className="pt-0">
          <FormLabel>Article URL</FormLabel>
          <FormControl>
            <Input
              {...field}
              onBlur={onBlurArticleUrl}
              onKeyDown={onEnterArticleUrl}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );

  const renderNameInput = () => (
    <FormField
      control={form.control}
      name="name"
      render={({ field }) => (
        <FormItem className="pt-0">
          <FormLabel>Campaign Name</FormLabel>
          <FormControl>
            <Input placeholder="e.g. Tier 1 article boost" {...field} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );

  const renderGoalSelection = () =>
    showSupportGoals && (
      <FormField
        control={form.control}
        name="goal"
        render={({ field }) => (
          <FormItem className="pt-0">
            <FormLabel>Campaign Goal</FormLabel>
            <FormControl>
              <RadioGroup
                onValueChange={field.onChange}
                defaultValue={field.value}
                className="flex"
              >
                {goalOptions.map((goalOption) => (
                  <RadioJumboGroupItem
                    key={goalOption.value}
                    id={goalOption.value}
                    selectedValue={field.value}
                    {...goalOption}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    );

  const renderPlatformSelection = () => (
    <FormField
      control={form.control}
      name="channel"
      render={({ field }) => (
        <FormItem className="pt-0">
          <FormLabel>Platform</FormLabel>
          <FormControl>
            <RadioGroup
              onValueChange={field.onChange}
              defaultValue={field.value}
              className="flex"
            >
              {platformOptions.map((platformOption) => (
                <RadioJumboGroupItem
                  key={platformOption.value}
                  id={platformOption.value}
                  selectedValue={field.value}
                  {...platformOption}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );

  const onSubmit = async (values: z.infer<typeof createFormSchema>) => {
    if (adBuilderRedesign) {
      setSubmitted(true);
      const campaign: Campaign = {
        name: values.name,
        channel: values.channel,
        total_budget: "500",
      };
      if (["awareness", "traffic"].includes(values.channel))
        campaign.campaign_type = values.channel as CampaignTypeEnum;
      createCampaign(campaign);
    } else {
      await dispatch(
        generateArticle(values.url, values.name, values.channel, navigate),
      );
    }
  };

  return (
    <Form {...form}>
      <DialogContent className="min-w-[654px]">
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogHeader>
            <DialogTitle>
              {url ? "New Amplification Campaign" : "New Campaign"}
            </DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-5 mt-5">
            {!submitting ? (
              <>
                {renderUrlInput()}
                {renderNameInput()}
                {renderGoalSelection()}
                {renderPlatformSelection()}
              </>
            ) : (
              <BuilderLoader />
            )}
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="secondary">Cancel</Button>
            </DialogClose>
            <Button
              type="submit"
              disabled={submitting || isLoadingCreate}
              className="gap-2"
              state={submitting || isLoadingCreate ? "loading" : "default"}
            >
              {submitting || isLoadingCreate ? "Creating" : "Create"}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Form>
  );
};

export default CreateCampaignDialog;
