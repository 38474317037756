import { formattedDateToUTC } from "@/components/news/shared/utils";
import { Separator } from "@/components/ui/controls/separator";
import { DateField, DateInput } from "@/components/ui/datefield";
import ButtonGroup from "@/components/ui/molecules/ButtonGroup";
import { EditButton } from "@/components/ui/molecules/EditButton";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/navigation/popover";
import { toast } from "@/components/ui/toast";
import { cn } from "@/lib/utils";
import { getLocalTimeZone, parseDate } from "@internationalized/date";
import { Calendar as CalendarIcon, X } from "@phosphor-icons/react";
import { format } from "date-fns-tz";
import { useEffect, useRef, useState } from "react";
import { Calendar } from "../../ui/data-display/calendar";

interface EditableDateProps {
  initialDate?: string | null;
  onSave: (date: Date) => void;
  styles?: string;
  nonHoverClick?: () => void;
}

const EditableDate: React.FC<EditableDateProps> = ({
  initialDate = new Date().toISOString(),
  onSave,
  styles,
  nonHoverClick,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const dateFieldRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [inputDate, setInputDate] = useState(
    initialDate ? parseDate(format(initialDate, "yyyy-MM-dd")) : null,
  );
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    initialDate ? new Date(initialDate) : null,
  );
  const [monthDate, setMonthDate] = useState<Date | null>(
    initialDate ? new Date(initialDate) : null,
  );

  const handleEditClick = () => {
    setIsEditing(true);
    setIsHovered(false);
  };

  const handleDateChange = (date: Date | undefined) => {
    setSelectedDate(date ?? null);
    setInputDate(date ? parseDate(format(date, "yyyy-MM-dd")) : null);
  };

  const handleDateSave = () => {
    if (!selectedDate) {
      toast.error("Please select a date.");
      return;
    }
    setIsEditing(false);
    onSave(selectedDate);
  };

  const handleDateCancel = () => {
    setIsEditing(false);
    setSelectedDate(initialDate ? new Date(initialDate) : null);
    setInputDate(
      initialDate ? parseDate(format(initialDate, "yyyy-MM-dd")) : null,
    );
  };

  const handleDateClear = () => {
    setSelectedDate(null);
    setInputDate(null);
  };

  useEffect(() => {
    setSelectedDate(initialDate ? new Date(initialDate) : null);
    setInputDate(
      initialDate ? parseDate(format(initialDate, "yyyy-MM-dd")) : null,
    );
  }, [initialDate]);

  useEffect(() => {
    if (inputDate) {
      setSelectedDate(inputDate.toDate(getLocalTimeZone()));
      setMonthDate(inputDate.toDate(getLocalTimeZone()));
    }
  }, [inputDate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event?.target as Node) &&
        !popoverRef.current?.contains(event?.target as Node)
      ) {
        handleDateCancel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const focusDateInput = () => {
    if (dateFieldRef.current) {
      setInputDate(null);

      const inputElement = dateFieldRef.current.querySelector("input");
      if (inputElement) {
        inputElement.focus();
      }
    }
  };

  return (
    <div
      className="inline-flex items-start justify-start text-xs text-slate-500"
      ref={containerRef}
    >
      {isEditing ? (
        <div className="w-full relative">
          <Popover open={true}>
            <PopoverTrigger asChild>
              <div
                className="relative py-1 pl-1.5 -ml-1.5 pr-2 rounded-sm text-xs text-left overflow-hidden leading-tight text-slate-900 line-clamp-3 inline-flex items-center border border-blue-600"
                onDoubleClick={focusDateInput}
              >
                <CalendarIcon
                  className="fill-slate-600"
                  size={16}
                  onClick={handleDateCancel}
                />
                <DateField
                  value={inputDate}
                  onChange={setInputDate}
                  className="pl-1 pr-4"
                  ref={dateFieldRef}
                >
                  <DateInput variant="ghost" />
                </DateField>
                <div
                  className="rounded-full w-[13px] h-[13px] p-0.5 bg-gray-400 cursor-pointer"
                  onClick={handleDateClear}
                >
                  <X className="text-white" size={9} />
                </div>
              </div>
            </PopoverTrigger>
            <PopoverContent
              className="w-auto p-0"
              align="start"
              ref={popoverRef}
            >
              <Calendar
                initialFocus
                mode="single"
                month={monthDate ?? new Date()}
                selected={selectedDate ?? undefined}
                onSelect={handleDateChange}
                onMonthChange={setMonthDate}
                numberOfMonths={1}
              />
              <Separator />
              <div className="p-2.5">
                <ButtonGroup
                  primaryLabel="Save"
                  primaryAction={handleDateSave}
                  secondaryLabel="Cancel"
                  secondaryAction={handleDateCancel}
                />
              </div>
            </PopoverContent>
          </Popover>
        </div>
      ) : (
        <div
          className={cn(
            "relative py-2 text-xs text-left overflow-hidden leading-tight pl-1.5 pr-8 text-slate-900 line-clamp-3 group -ml-1.5 hover:bg-slate-200/20 rounded",
            styles,
          )}
          role={nonHoverClick && "button"}
          onClick={() => !isHovered && nonHoverClick?.()}
          onKeyDown={(e) => {
            if (!isHovered && (e.key === "Enter" || e.key === " ")) {
              nonHoverClick?.();
            }
          }}
        >
          <p className="min-w-24 text-xs text-gray-800">
            {selectedDate
              ? formattedDateToUTC(selectedDate)
              : "No date selected"}
          </p>
          <EditButton
            onEditClick={handleEditClick}
            onHoverChange={setIsHovered}
            className="top-1.5 right-2"
          />
        </div>
      )}
    </div>
  );
};

export default EditableDate;
