import { MEDIUM_WINDOW_WIDTH } from "@/config";
import { cn } from "@/lib/utils";
import { useWindowSize } from "@uidotdev/usehooks";
import { type ReactNode, useLayoutEffect, useState } from "react";
import SettingsSidebar from "../common/SettingsSidebar";

const SettingsLayout: React.FC<{ children: ReactNode[] | ReactNode }> = ({
  children,
}: {
  children: ReactNode[] | ReactNode;
}) => {
  const { width } = useWindowSize();
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  useLayoutEffect(() => {
    setSidebarVisible(width ? width > MEDIUM_WINDOW_WIDTH : true);
  }, [width]);

  return (
    <div
      className={cn(
        "grid h-screen overflow-hidden",
        isSidebarVisible && "grid-cols-[min-content_1fr]",
        !isSidebarVisible && "grid-cols-1",
      )}
    >
      {isSidebarVisible ? <SettingsSidebar /> : <></>}
      <div>{children}</div>
    </div>
  );
};

export default SettingsLayout;
