import { graphql } from "../graphql";
import { tierConfigurationFragment } from "./tier";

const FeedFragment = graphql(`
  fragment FeedFragment on FeedType @_unmask {
    id
    name
  }
`);

export const FeedOverviewQuery = graphql(
  `
    query FeedOverviewQuery {
      feeds {
        ...FeedFragment
        updatedAt
        jobLastRun
      }
    }
  `,
  [FeedFragment],
);

export const SideBarDataQuery = graphql(
  `
    query SideBarDataQuery {
      feeds {
        ...FeedFragment
        terms {
          term
          description
        }
        knownTags {
          tag
        }
      }
      campaigns {
        id
        name
      }
    }
  `,
  [FeedFragment],
);

export type SideBarQueryFeedType = ReturnType<
  Exclude<typeof SideBarDataQuery.__apiType, undefined>
>["feeds"];

export type SideBarQueryCampaignsType = ReturnType<
  Exclude<typeof SideBarDataQuery.__apiType, undefined>
>["campaigns"];

export const NewsFeedsDataQuery = graphql(`
  query NewsFeedsDataQuery($dayFilter: Int) {
    newsfeedData(daysToFilter: $dayFilter) {
      id
      feedName
      jobLastRun
      feedItems {
        id
        articleId
        summary
        imageUrl
        headline
        maxScore
        maxSocial
        prominence
        storyArticleCount
        storyChartIds
        storyChartScores
        storyFirstArticlePublish
        itemType
        articleLastUpdateDate
        articleReadership
        maxDomainAuthority
        storyLastArticlePublish
        articleUrl
        articleNewsCategory
        storyPublishers {
          iconUrl
          name
        }
        articlePublisher {
          id
          name
          url
          logoUrl
        }
        articleMentions {
          snippet
          term
          location
        }
        storyPublishers {
          iconUrl
          name
        }
        discoveredTags {
          id
          tag
          tagKind
        }
        articleAuthors {
          id
          name
        }
      }
    }
  }
`);

export const NewsFeedsSearchQuery = graphql(`
  query NewsFeedSearchQuery(
    $feedId: Int!
    $searchTerm: String!
    $selectedItems: [Int!]!
  ) {
    feedSearch(
      input: {
        search: {
          feedId: $feedId
          searchTerm: $searchTerm
          selectedItems: $selectedItems
          advancedSearch: true
        }
      }
    ) {
      term
      items {
        id
        field {
          name
          content
        }
      }
    }
  }
`);

export type NewsFeedsSearchQueryType = ReturnType<
  Exclude<typeof NewsFeedsSearchQuery.__apiType, undefined>
>;

export const UpdateArticleMutation = graphql(`
  mutation UpdateArticleMutation($input: OverrideFeedArticleInput!) {
    overrideFeedArticle(input: $input) {
      ... on FeedArticleType {
        id
        overrides
      }
      ... on OperationInfo {
        __typename
        messages {
          message
        }
      }
    }
  }
`);

export type UpdateArticleMutationInputType = Parameters<
  Exclude<typeof UpdateArticleMutation.__apiType, undefined>
>[0]["input"]["overrides"];

export const FeedPageV2Fragment = graphql(`
  fragment FeedPageV2Fragment on FeedPageV2 @_unmask {
    id
    articleId
    headline
    overriddenHeadline
    imageUrl
    maxScore
    articlePublisher {
      id
      logoUrl
      name
      url
    }
    overriddenArticlePublisher {
      id
      logoUrl
      name
      url
    }
    articleAuthors {
      id
      name
    }
    overriddenArticleAuthors {
      id
      name
    }
    overriddenDatePublished
    articleNewsCategory
    overriddenArticleNewsCategory
    articleReadership
    articleSentiment {
      polarity
      rationale
    }
    overriddenArticleSentiment {
      polarity
      rationale
      intensity
    }
    firstArticleMention {
      snippet
      term
      location
    }
    articleUrl
    datePublished
    feedId
    headline
    id
    imageUrl
    articleLastUpdateDate
    knownTags
    maxDomainAuthority
    maxScore
    prominence
    maxSocial
    sortDate
    summary
    overriddenSummary
    topics
  }
`);

export const NewsFeedV2Query = graphql(
  `
  query FeedQueryV2($feedId: ID!, $dayFilter: Int, $startDate: Date, $endDate: Date) {
    feedDataV2(filters: {daysToFilter: $dayFilter, feedId: $feedId, startDate: $startDate, endDate: $endDate}, pagination: null) {
      ...FeedPageV2Fragment
    }
    readershipConfiguration {
      ...TierConfig
      tenant {
        enableReadership
        }
      }
    tierConfiguration {
      ...TierConfig
      tenant {
        enableCustomTierScoring
      }
    }
    tier1Pub: publisherConfiguration(tier: TIER_ONE) {
      __typename
      publishers {
          id
        }
    }
    tier2Pub: publisherConfiguration(tier: TIER_TWO) {
      __typename
      publishers {
          id
        }
    }
    tier3Pub: publisherConfiguration(tier: TIER_THREE) {
      __typename
      publishers {
          id
        }
    }
  }
`,
  [FeedPageV2Fragment, tierConfigurationFragment],
);

export type DomainOrReadershipConfig =
  | ReturnType<
      Exclude<typeof NewsFeedV2Query.__apiType, undefined>
    >["readershipConfiguration"]
  | ReturnType<
      Exclude<typeof NewsFeedV2Query.__apiType, undefined>
    >["tierConfiguration"];

export const DiscoveredTagQuery = graphql(
  `
    query DiscoveredTagQuery(
      $feedId: Int!
      $startDate: DateTime
      $endDate: DateTime
    ) {
      feedArticleTagsByFeed(
        feedId: $feedId
        startDate: $startDate
        endDate: $endDate
        tagKinds: ["events", "topics", "initiatives", "people", "themes"]
      ) {
        tagKind
        tag
        feedArticleIds
      }
    }
  `,
);

export const NewsFeedPublishers = graphql(`
  query NewsFeedPublishers($pubFilter: String = "", $pubLimit: Int = 50) {
    publishers(pagination: { limit: $pubLimit }, filter: $pubFilter) {
      id
      name
      url
      logos {
        image {
          url
        }
      }
    }
  }
`);

export type NewsFeedPublishersDataType = ReturnType<
  Exclude<typeof NewsFeedPublishers.__apiType, undefined>
>["publishers"];

export type NewsFeedPublisherType = NewsFeedPublishersDataType[number];

export type NewsFeedDataType = ReturnType<
  Exclude<typeof NewsFeedsDataQuery.__apiType, undefined>
>["newsfeedData"][number];

export type FeedType = ReturnType<
  Exclude<typeof NewsFeedV2Query.__apiType, undefined>
>["feedDataV2"][number];

export type ArticleSentimentType = FeedType["overriddenArticleSentiment"];
export type ArticlePublisherType = FeedType["articlePublisher"];
export type ArticleNewsCategoryType = FeedType["articleNewsCategory"];

export interface FeedWithAugTypes extends FeedType {
  searchHit?: {
    mention: string;
    term: string;
  };
  tierLevel?: string;
}

export const deleteFeedArticleMutation = graphql(`
  mutation deleteFeedArticle($input: DeleteFeedArticleInput!) {
    deleteFeedArticle(input: $input) {
      ... on SuccessResponse {
        __typename
        message
      }
      ... on OperationInfo {
        __typename
        messages {
          message
        }
      }
    }
  }
`);

export type DeleteExecuteType = Exclude<
  typeof deleteFeedArticleMutation.__apiType,
  undefined
>;

export type DeleteFeedArticleType =
  ReturnType<DeleteExecuteType>["deleteFeedArticle"];

export type DeleteFeedArticleParemeters =
  Parameters<DeleteExecuteType>[0]["input"];

export type FeedDataTermsType = {
  term: string;
  description: string;
};

export const FEED_MUTATION = graphql(`
  mutation FeedMutation($data: FeedUpdateInput!, $feedId: Int!) {
    updateFeed(data: $data, id: $feedId) {
      ... on FeedType {
        name
        terms {
          id
          term
          description
        }
        knownTags {
          id
          tag
        }
      }
    }
  }
`);

export type FeedMutationDataType = Parameters<
  Exclude<typeof FEED_MUTATION.__apiType, undefined>
>[0]["data"];

export const CREATE_FEED_MUTATION = graphql(`
  mutation CreateFeedMutation($data: FeedCreateInput!) {
    createFeed(data: $data) {
      ... on FeedType {
        id
        name
        terms {
          term
          description
          id
        }
        knownTags {
          id
          tag
        }
      }
    }
  }
`);

export type CreateFeedMutationDataType = Parameters<
  Exclude<typeof CREATE_FEED_MUTATION.__apiType, undefined>
>[0]["data"];

export const CREATE_ARTICLE_SUBSCRIPTION = graphql(`
  subscription CreateArticleSubscription($articleUrl: String!, $feedId: ID!) {
    articleCreation(articleUrl: $articleUrl, feedId: $feedId) {
      status
      feedArticle {
        id
      }
      uiMessage {
        type
        message
      }
    }
  }
`);

export const UPLOAD_CSV_FILE = graphql(`
  mutation UploadCSVFile($feedId: ID!, $file: Upload!) {
    uploadCsvFile(input: { feedId: $feedId, file: $file }) {
      ... on SuccessResponse {
        message
      }
      ... on OperationInfo {
        messages {
          kind
          message
        }
      }
    }
  }
`);

export type ArticleCreationDataType = ReturnType<
  Exclude<typeof CREATE_ARTICLE_SUBSCRIPTION.__apiType, undefined>
>["articleCreation"];
export type ArticleCreationStatusType = ReturnType<
  Exclude<typeof CREATE_ARTICLE_SUBSCRIPTION.__apiType, undefined>
>["articleCreation"]["status"];

export const SingleFeedDataV2Query = graphql(
  `
    query singleFeedDataV2($feedArticleId: ID!, $feedId: ID!) {
      singleFeedDataV2(feedArticleId: $feedArticleId, feedId: $feedId) {
        ...FeedPageV2Fragment
      }
      readershipConfiguration {
        ...TierConfig
        tenant {
          enableReadership
          }
        }
      tierConfiguration {
        ...TierConfig
        tenant {
          enableCustomTierScoring
        }
      }
      tier1Pub: publisherConfiguration(tier: TIER_ONE) {
        __typename
        publishers {
            id
          }
      }
      tier2Pub: publisherConfiguration(tier: TIER_TWO) {
        __typename
        publishers {
            id
          }
      }
      tier3Pub: publisherConfiguration(tier: TIER_THREE) {
        __typename
        publishers {
            id
          }
      }
    }
  `,
  [FeedPageV2Fragment, tierConfigurationFragment],
);
