// @Components
import {
  BarChart,
  type BarChartEventProps,
  ChartSkeleton,
  NoDataView,
} from "@/components/ui/BarChart.tsx";
import { ButtonGroup } from "@/components/ui/button.tsx";

// @Query
import {
  getFormatterForPeriod,
  useGetFeedVolume,
} from "./useFeedVolumeGraphData";

// @Store
import useFeedStore from "@/store/useFeedStore";

//@Utils
import { addCommasToNumbers } from "@/lib/utils/prettyName";

type VolumeDataPeriod = {
  categoryClicked: string; // "volume"
  endOfPeriod: string; // ISO 8601 datetime string
  eventType: string; // "bar"
  startOfPeriod: string; // ISO 8601 datetime string
  volume: number; // numeric value
  __typename: string;
};

const FeedVolumeGraph = () => {
  const { feedVolume, fetching: isFetchingFeedVolume } = useGetFeedVolume();
  const [
    period,
    setPeriod,
    filterDays,
    //isFetchingFeed,
    setFeedVolumeSelectedDate,
    setFeedVolume,
  ] = useFeedStore((state) => [
    state.period,
    state.setPeriod,
    state.filterDays,
    //state.fetchingFeed,
    state.setFeedVolumeSelectedDate,
    state.setFeedVolume,
  ]);

  //const filteredItemsLength = useFilteredItemsLength();

  // button configurations
  const buttonConfig = [
    {
      label: "Day",
      onClick: () => setPeriod("day"),
    },
    {
      label: "Week",
      onClick: () => setPeriod("week"),
    },
    {
      label: "Month",
      onClick: () => setPeriod("month"),
    },
  ];

  const getDefaultSelectedIndex = () => {
    if (period === "day") return 0;
    if (period === "week") return 1;
    if (period === "month") return 2;
    return 2;
  };

  const handleBarClick = (v: BarChartEventProps) => {
    setFeedVolume(
      v ? (v.volume as unknown as VolumeDataPeriod["volume"]) : null,
    );
    if (period === "day") {
      setFeedVolumeSelectedDate(
        v
          ? {
              startDate: v.startOfPeriod as VolumeDataPeriod["startOfPeriod"],
              endDate: null,
            }
          : null,
      );
    } else
      setFeedVolumeSelectedDate(
        v
          ? {
              startDate: v.startOfPeriod as VolumeDataPeriod["startOfPeriod"],
              endDate: v.endOfPeriod as VolumeDataPeriod["endOfPeriod"],
            }
          : null,
      );
  };

  if (isFetchingFeedVolume)
    return (
      <ChartSkeleton
        className={"lg:max-w-[932px] lg:mx-auto flex flex-col gap-9 pb-7 pt-2"}
      />
    );

  /**
   * TODO: Add this back in when we have filters wired up
  if (filteredItemsLength === 0 && !isFetchingFeed) {
    return <></>;
  }
 */

  const renderDataView = () => {
    if (!feedVolume || feedVolume.length === 0) {
      return <NoDataView className={"top-[-20px]"} />;
    }

    return (
      <BarChart
        className="h-80"
        data={feedVolume}
        index={"startOfPeriod"}
        categories={["volume"]}
        onValueChange={(v) => handleBarClick(v)}
        colors={["violet"]}
        borderRadius={6}
        showLegend={false}
        intervalType={filterDays === 90 && period === "day" ? 3 : 0}
        formatXAxisTick={(v, index) => {
          const item = feedVolume[index];
          return getFormatterForPeriod(v, period, {
            startDate: item?.startOfPeriod,
            endDate: item?.endOfPeriod,
          });
        }}
        valueFormatter={(v) => (v ? addCommasToNumbers(v) : "0")}
        rotateXAxisTick={feedVolume.length > 12}
      />
    );
  };

  return (
    <div
      className={"lg:max-w-[932px] lg:mx-auto flex flex-col gap-9 pb-7 pt-2"}
    >
      <div className={"flex justify-end"}>
        <ButtonGroup
          buttonConfig={buttonConfig}
          defaultSelectedIndex={getDefaultSelectedIndex()}
        />
      </div>
      {renderDataView()}
    </div>
  );
};

export default FeedVolumeGraph;
