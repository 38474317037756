import {
  type FeedWithAugTypes,
  SingleFeedDataV2Query,
} from "@/data-access/news/newsFeeds";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";

export const useFeedSingleArticleData = () => {
  const { articleId, feedId } = useParams();

  const [{ data, error, fetching: loading }, reloadData] = useQuery({
    query: SingleFeedDataV2Query,
    requestPolicy: "cache-and-network",
    variables: {
      feedId: `${feedId}`,
      feedArticleId: `${articleId}`,
    },
  });

  const article = data?.singleFeedDataV2 as Partial<FeedWithAugTypes>;

  return { loading, error, reloadData, article, data };
};
