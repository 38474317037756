import FeedArticleRemove from "@/components/news/article/FeedArticleRemove.tsx";
import FeedArticleSkeleton from "@/components/news/article/FeedArticleSkeleton";
import FeedEmptyState from "@/components/news/feed/FeedEmptyState";
import FeedItem from "@/components/news/feed/FeedItem";
import { getArticleHeight } from "@/components/news/shared/getArticleHeight";
import { Button } from "@/components/ui/button.tsx";
import type { FeedWithAugTypes } from "@/data-access/news/newsFeeds";
import { useFeature } from "@/lib/feature-management";
import useArticleDeletionStore from "@/store/articleDeleteSlice.ts";
import type { FilterData } from "@/store/feedHelpers.ts";
import useFeedStore from "@/store/useFeedStore";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useEffect, useRef, useState } from "react";
import FeedFilterHeader from "./FeedFilterHeader.tsx";
import { FeedSearch } from "./FeedSearch";
import FeedVolumeGraph from "./FeedVolumeGraph/FeedVolumeGraph.tsx";

// @Icons
import { Trash } from "@phosphor-icons/react";

// @Molecules
import ActionBar from "@/components/ui/molecules/ActionBar.tsx";

// @Utils
import { addCommasToNumbers, pluralize } from "@/lib/utils/prettyName.ts";

interface FeedContentProps {
  feedId: string;
  searchTerms: string;
  feedItems: Partial<FeedWithAugTypes>[];
  className?: string;
}

const FeedContent: React.FC<FeedContentProps> = ({
  feedId,
  searchTerms,
  feedItems,
  className,
}) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const [zIndexMap, setZIndexMap] = useState<Record<number, number>>({});
  const [fetching, isEmptyFeed, removalArr, categoryAppliedFilters] =
    useFeedStore((state) => [
      state.fetchingFeed,
      state.feedItems.length === 0,
      state.removalArr,
      state.categoryAppliedFilters,
    ]);
  const [
    articleIdsToDelete,
    closeActionBar,
    deleteAllArticles,
    isDeleteModalOpen,
    setDeleteModalOpen,
    toggleDeleteAllArticles,
  ] = useArticleDeletionStore((state) => [
    state.articleIdsToDelete,
    state.closeActionBar,
    state.deleteAllArticles,
    state.isDeleteModalOpen,
    state.setDeleteModalOpen,
    state.toggleDeleteAllArticles,
  ]);
  const hasChartAccess = useFeature("enable-charts", false);
  useEffect(() => {
    if (mainRef.current) mainRef.current.scrollTo(0, 0);
  }, [feedId, searchTerms]);

  const estimateSize = (index: number) => {
    const item = feedItems?.[index];
    if (!item?.id || removalArr.includes(item.id)) return 0;
    return getArticleHeight(item);
  };

  const rowVirtualizer = useVirtualizer({
    getItemKey: (index) => feedItems?.[index]?.id ?? index,
    count: feedItems?.length ?? 0,
    getScrollElement: () => mainRef.current,
    estimateSize,
    gap: 18,
    overscan: 20,
  });

  const preloadedImageRefs = useRef<Record<number, HTMLImageElement>>({});

  const preloadImage = async (src: string, itemId: number) => {
    if (!preloadedImageRefs.current[itemId]) {
      const img = new Image();
      img.loading = "eager";
      img.src = src;
      preloadedImageRefs.current[itemId] = img;
    }
  };

  useEffect(() => {
    const preloadAhead = 5;
    const visibleItems = rowVirtualizer.getVirtualItems();

    (async () => {
      for (let i = 0; i < (visibleItems?.length || 0) + preloadAhead; i++) {
        if (i >= (visibleItems?.length || 0)) break;
        const currentItem = visibleItems?.[i];
        if (currentItem) {
          const item = feedItems?.[currentItem.index];
          if (item?.imageUrl && item?.id) {
            await preloadImage(item.imageUrl, item.id);
          }
        }
      }
    })();
  }, [feedItems, rowVirtualizer]);

  const handleMouseEnter = (itemId: number) => {
    setZIndexMap((prev) => ({ ...prev, [itemId]: 10 }));
  };

  const handleMouseLeave = (itemId: number) => {
    setZIndexMap((prev) => ({ ...prev, [itemId]: 0 }));
  };

  const numberOfArticles = articleIdsToDelete.length;

  const handleCloseFeedArticleRemoveModal = () => {
    setDeleteModalOpen(false);
    closeActionBar();
  };

  const filterData: FilterData[] = Object.entries(categoryAppliedFilters).map(
    ([key, value]) => {
      const options = Object.entries(value.itemCounts ?? {});
      return {
        options,
        selectedOptions: value.selected ?? [],
        categoryKey: key,
      };
    },
  );

  return (
    <div className={className}>
      {numberOfArticles ? (
        <ActionBar onClose={closeActionBar}>
          <div className={"flex justify-between items-center w-full"}>
            <div className={"text-sm ml-6"}>
              <span className={"font-semibold pr-1"}>
                {addCommasToNumbers(numberOfArticles)}
              </span>
              <span>{pluralize(numberOfArticles, "article")} selected</span>
            </div>
            <Button
              variant={"outline"}
              className={"mr-4"}
              onPressChange={() => setDeleteModalOpen(true)}
            >
              <Trash size={16} className={"text-red-750"} />
              <span className={"ml-2"}>Delete</span>
            </Button>
          </div>
        </ActionBar>
      ) : (
        <> </>
      )}
      <FeedArticleRemove
        isOpen={isDeleteModalOpen}
        onClose={handleCloseFeedArticleRemoveModal}
        numberOfItemsToDelete={numberOfArticles}
      />
      <div
        ref={mainRef}
        className="bg-white overflow-y-auto overflow-x-hidden h-[calc(100vh-80px)] min-w-[932px] margin-auto flex-grow"
      >
        <FeedFilterHeader filterData={filterData} />
        {hasChartAccess && <FeedVolumeGraph />}
        <FeedSearch
          allArticleIds={feedItems
            .map((item) => item.id)
            .filter((id) => id !== undefined)}
          articleIdsToDelete={articleIdsToDelete}
          deleteAllArticles={deleteAllArticles}
          toggleDeleteAllArticles={toggleDeleteAllArticles}
          filterData={filterData}
        />
        <div className="lg:max-w-[932px] lg:mx-auto flex flex-col gap-9 pb-7">
          {fetching ? (
            Array.from({ length: 4 }, (_, index) => (
              // biome-ignore lint/suspicious/noArrayIndexKey: <okay for skeletons>
              <FeedArticleSkeleton key={index} />
            ))
          ) : feedItems?.length === 0 ? (
            <FeedEmptyState
              title={
                isEmptyFeed
                  ? "We're gathering your news"
                  : "No items match your filters"
              }
              body={
                isEmptyFeed
                  ? "We're searching billions of sources. Please check back soon."
                  : "Try adjusting your filters to find the news you're looking for."
              }
            />
          ) : (
            <div
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: "100%",
                position: "relative",
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                const item = feedItems?.[virtualItem.index];
                if (!item) return null;
                return (
                  <div
                    key={virtualItem.key}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: `${virtualItem.size}px`,
                      transform: `translateY(${virtualItem.start}px)`,
                      zIndex:
                        item?.id !== undefined ? zIndexMap[item.id] || 0 : 0,
                    }}
                    onMouseEnter={() =>
                      item?.id !== undefined && handleMouseEnter(item.id)
                    }
                    onMouseLeave={() =>
                      item?.id !== undefined && handleMouseLeave(item.id)
                    }
                  >
                    {item?.id && (
                      <FeedItem
                        image={preloadedImageRefs.current[item.id]}
                        setFeedArticleRemoveModalOpen={setDeleteModalOpen}
                        {...item}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeedContent;
