import { FeedContentLayout } from "@/components/layouts/FeedContentLayout";
import FeedContent from "@/components/news/feed/FeedContent";
import FeedFilters from "@/components/news/feed/FeedFilters";
import FeedHeader from "@/components/news/feed/FeedHeader";
import FeedSheets from "@/components/news/shared/FeedSheets";
import { cn } from "@/lib/utils";
import useFeedStore, { useFilteredFeedItems } from "@/store/useFeedStore";
import { useParams } from "react-router-dom";

const FeedPage: React.FC = () => {
  const { feedId } = useParams();
  const searchTerms = useFeedStore((state) => state.searchTerms);
  const filteredFeedItems = useFilteredFeedItems();

  return (
    <>
      <FeedContentLayout>
        <FeedHeader />
        <main
          className={cn(
            "grid transition-[grid-template-columns] duration-300 ease-in-out",
            // Mobile: Single column layout
            "grid-cols-[1fr]",
            // SM and up: Two column layout
            "sm:grid-cols-[minmax(150px,_150px)_1fr]",
            // MD: Wider filters column
            "md:grid-cols-[minmax(240px,_240px)_1fr]",
            // Custom breakpoint at 1285px: shrink back to 150px
            "[&]:max-[1285px]:grid-cols-[minmax(150px,_150px)_1fr]",
          )}
        >
          {feedId && (
            <>
              <div
                className={cn(
                  "hidden sm:block", // Simply hide on mobile, show on SM+
                  "w-full",
                )}
              >
                <FeedFilters />
              </div>
              <FeedContent
                feedId={feedId}
                searchTerms={searchTerms}
                feedItems={filteredFeedItems}
              />
            </>
          )}
        </main>
      </FeedContentLayout>
      <FeedSheets />
    </>
  );
};

export default FeedPage;
