import ButtonGroup from "@/components/ui/molecules/ButtonGroup";
import { EditButton } from "@/components/ui/molecules/EditButton";
import { JollyTextField } from "@/components/ui/textfield";
import { cn } from "@/lib/utils";
import type { Icon } from "@phosphor-icons/react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

interface EditableFieldProps {
  fieldName: string;
  initialFieldValue?: string;
  onSave: (newValue: string) => boolean;
  className?: string;
  nonEditStyles?: string;
  maxLines?: number;
  nonHoverClick?: () => void;
  children?: React.ReactNode;
  LeftIcon?: Icon;
}

const EditorActions = ({
  onSave,
  onCancel,
  offsetHeight,
}: {
  onSave: () => void;
  onCancel: () => void;
  offsetHeight: number;
}) => {
  return (
    <div
      className="absolute border shadow-md rounded bg-white border-b border-b-border py-3 px-2 rounded-2 flex flex-row-reverse justify-between w-full z-50"
      style={{ top: offsetHeight + 4 }}
    >
      <div className="flex flex-row-reverse gap-2">
        <ButtonGroup
          primaryLabel="Save"
          primaryAction={onSave}
          primaryDataAction="save"
          secondaryLabel="Cancel"
          secondaryAction={onCancel}
          secondaryDataAction="cancel"
          size="sm"
        />
      </div>
    </div>
  );
};

const EditableField: React.FC<EditableFieldProps> = ({
  initialFieldValue = "",
  onSave,
  className = "",
  nonEditStyles = "",
  maxLines = 1,
  nonHoverClick,
  LeftIcon,
  children,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedFieldName, setEditedFieldName] = useState(initialFieldValue);
  const [isHovered, setIsHovered] = useState(false);
  const divHeight = useRef<number>(0);

  const divRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null);
  const [textAreaHeight, setTextAreaHeight] = useState<number>(0); // For editor actions, not the placeholder

  const updateTextAreaHeight = () => {
    if (textAreaRef.current) {
      setTextAreaHeight(textAreaRef.current.offsetHeight);
    }
  };

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(updateTextAreaHeight);

    if (textAreaRef.current) {
      resizeObserver.observe(textAreaRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [isEditing]);

  const handleEditClick = () => {
    divHeight.current = divRef.current?.offsetHeight ?? 0;
    if (divRef.current) {
      const initialHeight = divRef.current.offsetHeight;
      setTextAreaHeight(initialHeight); // Initial setup to align text field and editor actions
    }
    setIsEditing(true);
    setIsHovered(false);
    setTimeout(() => {
      if (textAreaRef.current) {
        textAreaRef.current.focus();
        // Move cursor to the end
        const length = textAreaRef.current.value.length;
        textAreaRef.current.setSelectionRange(length, length);
      }
      updateTextAreaHeight(); // Ensure it updates initially
    }, 0);
  };

  const handleFieldChange = (value: string) => {
    setEditedFieldName(value);
  };

  const handleFieldSave = () => {
    const saveSuccess = onSave(editedFieldName);

    if (saveSuccess) {
      setIsEditing(false);
    }
  };

  const handleFieldCancel = () => {
    setIsEditing(false);
    setEditedFieldName(initialFieldValue);
  };

  const handleBlur = (e: React.FocusEvent<Element>) => {
    const relatedTarget = e.relatedTarget as HTMLElement;
    if (
      relatedTarget &&
      ["save", "cancel"].includes(relatedTarget?.dataset?.action ?? "")
    ) {
      return;
    }
    handleFieldSave();
  };

  const handleEditingKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") {
      handleFieldCancel();
    } else if (e.key === "Enter" && !e.shiftKey) {
      handleFieldSave();
    }
  };

  useEffect(() => {
    setEditedFieldName(initialFieldValue);
  }, [initialFieldValue]);

  return (
    <div className="relative -mx-2">
      {isEditing ? (
        <div className="relative">
          {LeftIcon && (
            <LeftIcon
              className="text-gray-500 absolute top-1 left-2 z-50"
              size={16}
            />
          )}
          {/* Placeholder div with fixed height to preserve layout space */}
          <div style={{ height: divHeight.current }} />{" "}
          {/* Adjust height as needed */}
          <JollyTextField
            autoFocus={true}
            className={cn(
              "absolute top-0 left-0 w-full text-left z-40 overflow-hidden min-w-80 py-[5px] px-[7px] -my-[3px]",
              className,
            )}
            style={{ height: divHeight.current }}
            value={editedFieldName}
            onChange={handleFieldChange}
            onBlur={handleBlur}
            onKeyDown={handleEditingKeyDown}
            aria-label="Edit Field Name. Press Enter to save, Escape to cancel"
            textArea={maxLines > 1}
            ref={textAreaRef}
          />
          <EditorActions
            onSave={handleFieldSave}
            onCancel={handleFieldCancel}
            offsetHeight={textAreaHeight}
          />
        </div>
      ) : (
        <div
          className={cn(
            "relative min-h-6 py-1.5 px-2 overflow-hidden group rounded -my-1",
            isHovered ? "bg-slate-200/20" : nonEditStyles,
            className,
          )}
          role={nonHoverClick && "button"}
          ref={divRef}
          onClick={() => !isHovered && nonHoverClick?.()}
          onKeyDown={(e) => {
            if (!isHovered && (e.key === "Enter" || e.key === " ")) {
              nonHoverClick?.();
            }
          }}
        >
          {LeftIcon && (
            <LeftIcon
              className="text-gray-500 absolute top-1.5 left-2"
              size={16}
            />
          )}
          {children ? (
            children
          ) : (
            <p
              className={cn(
                "text-ellipsis overflow-hidden",
                maxLines === 1 ? "line-clamp-1" : `line-clamp-${maxLines}`,
              )}
            >
              {editedFieldName}
            </p>
          )}
          <EditButton
            onEditClick={handleEditClick}
            onHoverChange={setIsHovered}
            className="bottom-1 right-1"
          />
        </div>
      )}
    </div>
  );
};

export default EditableField;
