import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from "@/components/ui/data-display/tooltip";
import { useState } from "react";

interface SentimentTooltipProps {
  title: string | React.ReactNode;
  content: string;
  icon: React.ReactNode;
  children: React.ReactNode;
}

const SentimentTooltip: React.FC<SentimentTooltipProps> = ({
  title,
  content,
  icon,
  children,
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipTriggerClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };
  return (
    <Tooltip open={open} onOpenChange={setOpen}>
      <TooltipTrigger>
        <button
          className="flex flex-row space-x-1 items-center"
          onClick={handleTooltipTriggerClick}
        >
          {children}
        </button>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent align="end" className="px-2 py-4 max-w-md">
          <div className="flex flex-row space-x-1 items-start text-slate-700/90 mb-1">
            {icon}
            <div className="w-[360px] text-xs">
              {typeof title === "string" ? (
                <h6 className="font-bold whitespace-nowrap leading-4 mb-0.5">
                  {title}
                </h6>
              ) : (
                title
              )}
              <p className="font-normal text-xs whitespace-normal break-words">
                {content}
              </p>
            </div>
          </div>
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
};

export default SentimentTooltip;
