import {
  type FilterMap,
  getFilterParamsFromUrl,
  setFilterParamsToUrl,
} from "@/components/news/shared/utils";
import {
  type FeedType,
  type FeedWithAugTypes,
  NewsFeedsSearchQuery,
} from "@/data-access/news/newsFeeds";
import { client } from "@/lib/urqlProvider";
import { getPrettyName } from "@/lib/utils/prettyName";
import {
  differenceInDays,
  isSameDay,
  isWithinInterval,
  parseISO,
} from "date-fns";
import { toZonedTime } from "date-fns-tz";

export const TIER_1 = "Tier 1";
export const TIER_2 = "Tier 2";
export const TIER_3 = "Tier 3";

export type FilterValues = {
  selected: string[];
  relationship: "AND" | "OR" | "OR-ONLY";
  itemCounts: Record<string, number[]>;
  sorted?: boolean;
  logic: (item: Partial<FeedType>, selection: string) => boolean;
};

export type CategoryFilter = Partial<FilterValues>;

export type CategoryAppliedFilter = Record<string, CategoryFilter>;

export interface FilterData {
  selectedOptions: string[];
  categoryKey: string;
}

export async function filterFeedItems(
  feedItems: Partial<FeedType>[],
  searchTerms: string,
  categoryAppliedFilters: CategoryAppliedFilter,
  feedId?: number,
): Promise<
  [
    number,
    searchHit?: {
      mention: string;
      term: string;
    },
  ][]
> {
  if (shouldReturnOriginalFeed(searchTerms, categoryAppliedFilters)) {
    return Promise.resolve(
      feedItems.map((item) => [item.id ?? 0]) as [
        number,
        searchHit?: {
          mention: string;
          term: string;
        },
      ][],
    );
  }

  const itemdIds = feedItems
    .map((item) => item.articleId)
    .filter((id): id is number => id !== undefined);

  const canSearchRemote = searchTerms.length && feedId;

  const foundSearchHits = canSearchRemote
    ? await fetchSearchData(feedId, searchTerms, itemdIds)
    : false;

  return filterFeedItemsBySearchAndCategory(
    feedItems,
    foundSearchHits,
    categoryAppliedFilters,
    searchTerms,
  );
}

function shouldReturnOriginalFeed(
  searchTerms: string,
  categoryAppliedFilters: CategoryAppliedFilter,
): boolean {
  return !searchTerms.length && !hasAppliedFilters(categoryAppliedFilters);
}

function hasAppliedFilters(
  categoryAppliedFilters: CategoryAppliedFilter,
): boolean {
  return Object.keys(categoryAppliedFilters).some(
    (key) => (categoryAppliedFilters[key]?.selected?.length ?? 0) > 0,
  );
}

async function fetchSearchData(
  feedId: number,
  searchTerm: string,
  selectedFeedItems: number[],
): Promise<Record<string, string>> {
  const minLengthForSearch = 3;

  if (searchTerm?.length < minLengthForSearch) {
    return {};
  }

  const response = await client
    .query(NewsFeedsSearchQuery, {
      feedId,
      searchTerm,
      selectedItems: selectedFeedItems,
    })
    .toPromise();

  if (!response.data) {
    console.error("Issue with GraphQL response");
    return {};
  }

  return response.data.feedSearch.items.reduce(
    (acc, item) => {
      const { id, field } = item;
      const firstContent = field[0]?.content[0];
      if (!firstContent) return acc;
      acc[`${id}`] = firstContent;
      return acc;
    },
    {} as Record<string, string>,
  );
}

const getExcludedAndIncludedTerms = (
  searchTerms: string[],
): {
  includedTerms: string[];
  excludedTerms: string[];
} => {
  const includedTerms: string[] = [];
  const excludedTerms: string[] = [];

  for (const term of searchTerms) {
    if (term.startsWith("-")) {
      excludedTerms.push(term.slice(1));
    } else {
      includedTerms.push(term);
    }
  }

  return { includedTerms, excludedTerms };
};

const getItemSearchHitString = (
  item: Partial<FeedType>,
  foundSearchHits: Record<string, string>,
): { mention: string; term: string } | null => {
  if (!foundSearchHits) return null;

  const itemId = item.id ?? -1;
  const searchHitString = foundSearchHits[`${itemId}`];

  if (!searchHitString) return null;

  const emRegex = /<em><b>(.*?)<\/b><\/em>/g;
  const match = searchHitString.match(emRegex);
  const mention = searchHitString.replace(emRegex, "$1");
  const term = match ? match[0].replace(/<.*?>/g, "") : "";

  return { mention, term };
};

function filterFeedItemsBySearchAndCategory(
  feedItems: Partial<FeedType>[],
  foundSearchHits: Record<string, string> | false,
  categoryAppliedFilters: CategoryAppliedFilter,
  searchTerm: string,
): [
  number,
  searchHit?: {
    mention: string;
    term: string;
  },
][] {
  if (!feedItems || feedItems.length === 0) {
    return [];
  }

  const searchTerms = searchTerm.split(/\s+/);
  const { includedTerms, excludedTerms } =
    getExcludedAndIncludedTerms(searchTerms);
  const results = feedItems
    .filter((item) => {
      if (!item?.id) return false;
      const remoteSearchMatch =
        foundSearchHits &&
        Object.keys(foundSearchHits).includes(`${item.id ?? -1}`);
      const localSearchMatch = matchesSearchTerms(item, includedTerms);
      const excludeMatch = excludesTerms(item, excludedTerms);
      const filterMatch = matchesSelectedFilters(item, categoryAppliedFilters);

      return (
        (remoteSearchMatch || localSearchMatch) && filterMatch && !excludeMatch
      );
    })
    .map((item) => {
      const id = item.id as number;
      const searchHit = foundSearchHits
        ? getItemSearchHitString(item, foundSearchHits)
        : undefined;
      return [id, searchHit] as [
        number,
        searchHit?: { mention: string; term: string } | undefined,
      ];
    });

  return results;
}

export function processText(item: Partial<FeedType>): string {
  const { headline, summary, articleUrl } = item;

  const normalize = (text: string | null | undefined = "") =>
    text?.toLowerCase().replace(/[`‘’“”"']/g, "'") || "";

  const textToSearch = [headline, summary, articleUrl]
    .flat()
    .map(normalize)
    .join(" ");

  return `${textToSearch}`;
}

function excludesTerms(item: Partial<FeedType>, excludeTerms: string[]) {
  const fullTextToSearch = processText(item);
  return excludeTerms.some((term) => fullTextToSearch.includes(term));
}

export function matchesSearchTerms(
  item: Partial<FeedType>,
  searchTerms: string[],
) {
  const fullTextToSearch = processText(item);
  return searchTerms.every((term) => fullTextToSearch.includes(term));
}

export const getIntersection = (
  itemCounts: Record<string, number[]>,
  selected: string[],
  relationship?: string,
): number[] => {
  if (!selected.length) return [];

  let acc: Set<number> = new Set();

  for (const [tier, ids] of Object.entries(itemCounts)) {
    if (selected.includes(tier)) {
      const idSet = new Set(ids);
      if (relationship === "AND") {
        if (acc.size === 0) {
          acc = idSet;
        } else {
          acc = new Set([...acc].filter((id) => idSet.has(id)));
        }
      } else {
        for (const id of idSet) {
          acc.add(id);
        }
      }
    }
  }

  return Array.from(acc);
};

export const matchesSelectedFilters = (
  item: Partial<FeedType>,
  categoryAppliedFilters: CategoryAppliedFilter,
): boolean => {
  return Object.values(categoryAppliedFilters).every((filterValues) => {
    const { selected, itemCounts, relationship } = filterValues;

    if (!selected?.length || !itemCounts) {
      return true;
    }

    const ids = getIntersection(itemCounts, selected, relationship);

    return ids.includes(item.id ?? 0);
  });
};

export const initCategoryAppliedFilters = (
  filterParams: FilterMap = getFilterParamsFromUrl(),
) => {
  const filters: CategoryAppliedFilter = {
    "Impact Score": {
      selected: filterParams["Impact Score"] || [],
      relationship: "OR-ONLY",
      itemCounts: {
        "81-100": [],
        "61-80": [],
        "41-60": [],
        "21-40": [],
        "0-20": [],
        "-20 - -1": [],
        "-40 - -21": [],
        "-60 - -41": [],
        "-80 - -61": [],
        "-100 - -81": [],
      },
      sorted: false,
      logic: (item, selection) => rangeLogic(item.maxScore ?? 0, selection),
    },
    "Term Prominence": {
      selected: filterParams["Term Prominence"] || [],
      relationship: "OR",
      itemCounts: {
        Headline: [],
        Feature: [],
        Lede: [],
        "Passing Mention": [],
      },
      sorted: false,
      logic: (item, selection) =>
        (item.prominence ?? []).some(
          (prominence) => getPrettyName(prominence) === selection,
        ),
    },
    "Publication Tier": {
      selected: filterParams["Publication Tier"] || [],
      relationship: "OR-ONLY",
      itemCounts: {
        [TIER_1]: [],
        [TIER_2]: [],
        [TIER_3]: [],
      },
      sorted: false,
      logic: (item, selection) => publicationTierLogic(item, selection),
    },
    "AI Sentiment": {
      selected: filterParams["AI Sentiment"] || [],
      relationship: "OR-ONLY",
      itemCounts: {
        positive: [],
        neutral: [],
        negative: [],
      },
      sorted: false,
      logic: (item, selection) => sentimentLogic(item, selection),
    },
    "Social Engagement": {
      selected: filterParams["Social Engagement"] || [],
      relationship: "OR-ONLY",
      itemCounts: {
        "10,000+": [],
        "5,000-9,999": [],
        "3,000-4,999": [],
        "1,000-2,999": [],
        "500-999": [],
        "100-499": [],
        "1-99": [],
        "0": [],
      },
      sorted: false,
      logic: (item, selection) => {
        const engagement = item.maxSocial;
        if (engagement === undefined || engagement === null) return false;

        if (selection === "10,000+" && engagement >= 10000) return true;
        if (
          selection === "5,000-9,999" &&
          engagement >= 5000 &&
          engagement < 10000
        )
          return true;
        if (
          selection === "3,000-4,999" &&
          engagement >= 3000 &&
          engagement < 5000
        )
          return true;
        if (
          selection === "1,000-2,999" &&
          engagement >= 1000 &&
          engagement < 3000
        )
          return true;
        if (selection === "500-999" && engagement >= 500 && engagement < 1000)
          return true;
        if (selection === "100-499" && engagement >= 100 && engagement < 500)
          return true;
        if (selection === "1-99" && engagement >= 1 && engagement < 100)
          return true;
        if (selection === "0" && engagement === 0) return true;

        return false;
      },
    },
    Readership: {
      selected: filterParams.Readership || [],
      relationship: "OR-ONLY",
      itemCounts: {
        "10,000,001+": [],
        "5,000,001-10,000,000": [],
        "1,000,001-5,000,000": [],
        "500,001-1,000,000": [],
        "250,001-500,000": [],
        "100,001-250,000": [],
        "50,001-100,000": [],
        "10,001-50,000": [],
        "1-10,000": [],
      },
      sorted: false,
      logic: (item, selection) => {
        const { articleReadership } = item;
        if (articleReadership === undefined || articleReadership === null)
          return false;

        if (selection === "10,000,001+" && articleReadership >= 10000001)
          return true;
        const [min, max] = selection.split("-").map(Number);
        if (typeof min === "undefined" || typeof max === "undefined")
          return false;
        if (articleReadership >= min && articleReadership <= max) return true;
        return false;
      },
    },
    "Domain Authority": {
      selected: filterParams["Domain Authority"] || [],
      relationship: "OR-ONLY",
      itemCounts: Array.from({ length: 10 }, (_, i): [string, number[]] => [
        `${90 - i * 10}-${100 - (i === 0 ? 0 : i * 10 + 1)}`,
        [],
      ]).reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        {} as Record<string, number[]>,
      ),
      sorted: false,
      logic: (item, selection) =>
        rangeLogic(item.maxDomainAuthority ?? 0, selection),
    },
    "Content Category": {
      selected: filterParams["Content Category"] || [],
      relationship: "OR-ONLY",
      itemCounts: {},
      logic: (item, selection) => {
        const lowerCaseSelection = getPrettyName(selection.toLowerCase());
        const category = getPrettyName(
          item.articleNewsCategory?.toLowerCase() ?? "",
        );
        return lowerCaseSelection.toLowerCase() === category.toLowerCase();
      },
    },
    "Top Topics": {
      selected: filterParams["Top Topics"] || [],
      relationship: "OR",
      itemCounts: {},
      logic: (item, selection) =>
        (item.knownTags ?? []).some((tag: string) => tag === selection),
    },
    Publications: {
      selected: filterParams.Publications || [],
      relationship: "OR-ONLY",
      itemCounts: {},
      logic: publisherLogic,
    },
    Authors: {
      selected: filterParams.Authors || [],
      relationship: "OR-ONLY",
      itemCounts: {},
      logic: authorLogic,
    },
  };

  return filters;
};

export const sentimentLogic = (
  item: Partial<FeedWithAugTypes>,
  selection: string,
): boolean => {
  const sentiment = item?.articleSentiment;
  if (
    !sentiment?.polarity ||
    !["positive", "negative", "neutral"].includes(sentiment.polarity)
  )
    return false;

  switch (selection) {
    case "positive":
      return sentiment?.polarity === "positive";
    case "negative":
      return sentiment?.polarity === "negative";
    case "neutral":
      return sentiment?.polarity === "neutral";
    default:
      return false;
  }
};

export const publicationTierLogic = (
  item: Partial<FeedWithAugTypes>,
  selection: string,
): boolean => {
  const tier = item.tierLevel;
  if (!tier) return false;

  switch (selection) {
    case TIER_1:
      return tier === TIER_1;
    case TIER_2:
      return tier === TIER_2;
    case TIER_3:
      return tier === TIER_3;
    default:
      return false;
  }
};

export const updateCountsForCoverageTiers = (
  feedItem: Partial<FeedWithAugTypes>,
  coverageTier: CategoryFilter,
) => {
  const { id, tierLevel } = feedItem;
  if (!tierLevel || !id) return;

  updateItemCount(coverageTier, tierLevel, id);
};

export const updateCountsForSentiment = (
  feedItem: Partial<FeedWithAugTypes>,
  sentimentFilter: CategoryFilter,
) => {
  const { id, articleSentiment } = feedItem;
  if (!articleSentiment || !id) return;

  updateItemCount(sentimentFilter, articleSentiment.polarity, id);
};

export const updateCountsForPublisher = (
  feedItem: Partial<FeedType>,
  publisherFilter: CategoryFilter,
) => {
  const { id, articlePublisher } = feedItem;
  const publisher = articlePublisher?.name;
  if (!id || !publisher) {
    return;
  }

  if (publisher) updateItemCount(publisherFilter, publisher, id);
};

export const updateCountsForAuthors = (
  feedItem: Partial<FeedType>,
  authorFilter: CategoryFilter,
) => {
  const { id, articleAuthors } = feedItem;
  if (!id || !articleAuthors?.length) return;

  if (!authorFilter) {
    return;
  }

  for (const item of articleAuthors) {
    const { name } = item;
    updateItemCount(authorFilter, name, id);
  }
};

export const updateCountsForCategory = (
  feedItem: Partial<FeedType>,
  categoryFilter: CategoryFilter,
) => {
  const { id, articleNewsCategory } = feedItem;
  if (!id || !articleNewsCategory?.length) return;
  const articleCategory = getPrettyName(articleNewsCategory);

  if (!categoryFilter) {
    return;
  }

  updateItemCount(categoryFilter, articleCategory, id);
};

export const updateCountsForArticleMentions = (
  feedItem: Partial<FeedType>,
  termProminence: CategoryFilter,
) => {
  const { id, prominence } = feedItem;
  if (!id || !prominence?.length) return;

  if (termProminence) {
    for (const term of prominence) {
      const prettyProminence = getPrettyName(term);
      updateItemCount(termProminence, prettyProminence, id);
    }
  }
};

export const updateCountsForTopTopics = (
  feedItem: Partial<FeedType>,
  topTopics: CategoryFilter,
) => {
  const { id, knownTags: feedKnownTags } = feedItem;
  if (!id || !feedKnownTags) return;

  if (!topTopics) return;

  for (const tag of feedKnownTags) {
    updateItemCount(topTopics, tag, id);
  }
};

export function publisherLogic(
  item: Partial<FeedType>,
  selection: string,
): boolean {
  const lowerCaseSelection = selection.toLowerCase();
  const matchesName =
    item.articlePublisher?.name.toLowerCase() === lowerCaseSelection;
  // const matchesStoryPublishers =
  //   item.storyPublishers?.some(
  //     (publisher) => publisher?.name.toLowerCase() === lowerCaseSelection,
  //   ) ?? false;
  return matchesName;
}

export function authorLogic(
  item: Partial<FeedType>,
  selection: string,
): boolean {
  const lowerCaseSelection = selection.toLowerCase();
  const authorNames =
    item.articleAuthors?.map((item) => item.name.toLowerCase()) ?? [];
  const matchesName = authorNames.includes(lowerCaseSelection);
  return matchesName;
}

export const updateCountsForDomainAuthority = (
  feedItem: Partial<FeedType>,
  domainAuthorityFilter: CategoryFilter,
) => {
  const { id, maxDomainAuthority: authority } = feedItem;
  if (!id || authority === undefined || authority === null) return;

  if (!domainAuthorityFilter) return;

  const authorityLevel = getRangeLevel(authority, 10);
  updateItemCount(domainAuthorityFilter, authorityLevel, id);
};

export const updateCountsForQualityScore = (
  feedItem: Partial<FeedType>,
  qualityScoreFilter: CategoryFilter,
) => {
  const { id, maxScore: score } = feedItem;
  if (!id || score === undefined || score === null) return;

  if (!qualityScoreFilter) return;

  const scoreLevel = getQualityScoreRange(score);
  updateItemCount(qualityScoreFilter, scoreLevel, id);
};

export const updateCountsForSocialEngagement = (
  feedItem: Partial<FeedType>,
  socialEngagementFilter: CategoryFilter,
) => {
  const { maxSocial: engagement, id } = feedItem;
  if (engagement === undefined || engagement === null) return;

  if (!socialEngagementFilter || !id) return;

  const engagementLevels = [
    { min: 10000, label: "10,000+" },
    { min: 5000, label: "5,000-9,999" },
    { min: 3000, label: "3,000-4,999" },
    { min: 1000, label: "1,000-2,999" },
    { min: 500, label: "500-999" },
    { min: 100, label: "100-499" },
    { min: 1, label: "1-99" },
    { min: 0, label: "0" },
  ];

  const engagementLevel =
    engagementLevels.find((level) => engagement >= level.min)?.label || "0";

  updateItemCount(socialEngagementFilter, engagementLevel, id);
};

export const updateCountsForReadership = (
  feedItem: Partial<FeedType>,
  readershipFilter: CategoryFilter,
) => {
  const { articleReadership, id } = feedItem;
  if (articleReadership === undefined || articleReadership === null) return;

  if (!readershipFilter || !id) return;

  const redershipRanges = [
    { label: "1-10,000", min: 1, max: 10000 },
    { label: "10,001-50,000", min: 10001, max: 50000 },
    { label: "50,001-100,000", min: 50001, max: 100000 },
    { label: "100,001-250,000", min: 100001, max: 250000 },
    { label: "250,001-500,000", min: 250001, max: 500000 },
    { label: "500,001-1,000,000", min: 500001, max: 1000000 },
    { label: "1,000,001-5,000,000", min: 1000001, max: 5000000 },
    { label: "5,000,001-10,000,000", min: 5000001, max: 10000000 },
    { label: "10,000,001+", min: 10000001 },
  ];

  const readershipRangeLevel =
    redershipRanges.find(
      (r) =>
        ((r.max && articleReadership <= r.max) || !r.max) &&
        articleReadership >= r.min,
    )?.label || "0";

  updateItemCount(readershipFilter, readershipRangeLevel, id);
};

const getRangeLevel = (value: number, increment = 10) => {
  if (value >= 90) {
    return "90-100";
  }

  const lowerBound = Math.floor(value / increment) * increment;
  return `${lowerBound}-${lowerBound + increment - 1}`;
};

const getQualityScoreRange = (value: number): string => {
  if (value >= 81) return "81-100";
  if (value >= 61) return "61-80";
  if (value >= 41) return "41-60";
  if (value >= 21) return "21-40";
  if (value >= 0) return "0-20";
  if (value >= -20) return "-20 - -1";
  if (value >= -40) return "-40 - -21";
  if (value >= -60) return "-60 - -41";
  if (value >= -80) return "-80 - -61";
  return "-100 - -81";
};

const rangeLogic = (
  itemValue: number | undefined,
  selection: string,
): boolean => {
  if (itemValue === undefined || itemValue === null) return false;
  const [min, max] = selection.split("-").map(Number);
  if (typeof min === "undefined" || typeof max === "undefined") return false;
  return itemValue >= min && itemValue <= max;
};

type PartialFeedTag = {
  tagKind: string;
  tag: string;
  feedArticleIds: number[];
};

export const setCountsForThemes = (
  tags: PartialFeedTag[],
  categoryAppliedFilters: CategoryAppliedFilter,
) => {
  for (const tag of tags) {
    const tagKind = getPrettyName(tag.tagKind);
    let categoryFilter = categoryAppliedFilters[tagKind];

    if (!categoryFilter) {
      categoryFilter = {
        relationship: "OR",
        selected: [],
        itemCounts: {},
        logic: (item, selection) => {
          console.log({ item, selection });
          return true;
          // return (item. ?? []).some(
          //   (itemTag) =>
          //     getPrettyName(itemTag.tagKind) === tagKind &&
          //     itemTag.tag === selection,
          // );
        },
      };
      categoryAppliedFilters[tagKind] = categoryFilter;
    }

    if (!categoryFilter.itemCounts) return;

    if (!categoryFilter.itemCounts[tag.tag]) {
      categoryFilter.itemCounts[tag.tag] = [];
    }

    categoryFilter.itemCounts[tag.tag]?.push(...tag.feedArticleIds);
  }
};

export function calculateCounts(
  updatedCategoryAppliedFilters: CategoryAppliedFilter,
  filteredFeedItems: Partial<FeedWithAugTypes>[],
) {
  for (const feedItem of filteredFeedItems) {
    for (const key in updatedCategoryAppliedFilters) {
      const filter = updatedCategoryAppliedFilters[key];
      if (!filter) continue;
      if (!filter.itemCounts) filter.itemCounts = {};

      switch (key) {
        case "Term Prominence":
          updateCountsForArticleMentions(feedItem, filter);
          break;
        case "Publication Tier":
          updateCountsForCoverageTiers(feedItem, filter);
          break;
        case "AI Sentiment":
          updateCountsForSentiment(feedItem, filter);
          break;
        case "Publications":
          updateCountsForPublisher(feedItem, filter);
          break;
        case "Authors":
          updateCountsForAuthors(feedItem, filter);
          break;
        case "Content Category":
          updateCountsForCategory(feedItem, filter);
          break;
        case "Top Topics":
          updateCountsForTopTopics(feedItem, filter);
          break;
        case "Domain Authority":
          updateCountsForDomainAuthority(feedItem, filter);
          break;
        case "Impact Score":
          updateCountsForQualityScore(feedItem, filter);
          break;
        case "Social Engagement":
          updateCountsForSocialEngagement(feedItem, filter);
          break;
        case "Readership":
          updateCountsForReadership(feedItem, filter);
          break;
        default:
          break;
      }
    }
  }
}

export function updateAppliedFiltersToUrl(
  categoryAppliedFilters: CategoryAppliedFilter,
) {
  const appliedFilters = Object.entries(categoryAppliedFilters).reduce(
    (acc: string[], [key, value]) => {
      if (value.selected && value.selected.length > 0) {
        acc.push(`${key}:${value.selected.join(",")}`);
      }
      return acc;
    },
    [],
  );
  setFilterParamsToUrl(appliedFilters.join(";"));
}

export const updateItemCount = (
  filter: CategoryAppliedFilter[keyof CategoryAppliedFilter] | undefined,
  key: string,
  id: number,
) => {
  if (!filter || !key) return;
  if (!filter.itemCounts) filter.itemCounts = {};
  if (!filter.itemCounts[key]) filter.itemCounts[key] = [];
  filter.itemCounts[key].push(id);
};

/**
 * Return the period range type based on the start and end date
 *
 * @returns {string} - period range day | week | month
 * @param startDate
 * @param endDate
 * @param days
 */
export const getDateRangeType = (
  startDate: Date | undefined,
  endDate: Date | undefined,
  days: number | undefined,
): "day" | "week" | "month" | null => {
  let daysDifference = days;

  if (startDate && endDate) {
    daysDifference = differenceInDays(endDate, startDate);
  }

  if (daysDifference !== undefined) {
    if (daysDifference <= 31) {
      return "day";
    }

    if (daysDifference < 90) {
      return "week";
    }

    if (daysDifference >= 90) {
      return "month";
    }
  }

  return null;
};

/**
 * Checks if the feed item's date matches the feed volume selected date.
 *
 * @param itemDate - The date from the item (ISO string)
 * @param startOfPeriod - The selected date (Date object)
 * @param endOfPeriod - The selected date (Date object)
 * @returns {boolean} True if the dates match, false otherwise
 */
export const isWithinRange = (
  itemDate: string | undefined | null,
  startOfPeriod: string | null,
  endOfPeriod: string | null,
): boolean => {
  if (!itemDate || !startOfPeriod) {
    return false;
  }

  const parsedItemDate = toZonedTime(parseISO(itemDate), "UTC");
  const parsedStartDate = toZonedTime(parseISO(startOfPeriod), "UTC");

  // If endOfPeriod is not provided, check if the dates are the same
  if (!endOfPeriod) {
    return isSameDay(parsedItemDate, parsedStartDate);
  }

  const parsedEndDate = toZonedTime(parseISO(endOfPeriod), "UTC");
  return isWithinInterval(parsedItemDate, {
    start: parsedStartDate,
    end: parsedEndDate,
  });
};
