import { Button } from "@/components/ui/button";
import DateRangePicker, {
  type onApplyFn,
} from "@/components/ui/date-range-picker";
import { Skeleton } from "@/components/ui/feedback/skeleton";
import Checkbox from "@/components/ui/forms/checkbox";
import { Input, JollyTextField } from "@/components/ui/textfield";
import { useFeature } from "@/lib/feature-management";
import { cn } from "@/lib/utils";
import type { FilterData } from "@/store/feedHelpers";
import useFeedStore, {
  useFeedName,
  useFeedPercentage,
  useFilteredFeedItems,
} from "@/store/useFeedStore";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { FeedExport } from "./FeedExport";
import { FeedSort } from "./FeedSort";

// @Utils
import { addCommasToNumbers, pluralize } from "@/lib/utils/prettyName";

interface FeedSearchProps {
  allArticleIds: number[];
  articleIdsToDelete: number[];
  deleteAllArticles: boolean;
  toggleDeleteAllArticles: (ids: number[]) => void;
  filterData: FilterData[];
}

export const FeedSearch: React.FC<FeedSearchProps> = ({
  allArticleIds,
  articleIdsToDelete,
  deleteAllArticles,
  toggleDeleteAllArticles,
  filterData,
}) => {
  const [
    searchTerms,
    filterDays,
    isCustomDaysRange,
    startDate,
    endDate,
    isFiltered,
    fetchingFeed,
    filterBySearchTerms,
    updateDateRange,
    feedVolume,
  ] = useFeedStore((state) => [
    state.searchTerms,
    state.filterDays,
    state.isCustomDaysRange,
    state.startDate,
    state.endDate,
    state.isFiltered,
    state.fetchingFeed,
    state.filterBySearchTerms,
    state.updateDateRange,
    state.feedVolume,
  ]);

  const { feedPercentage, feedVolumePercentage } = useFeedPercentage();
  const feedName = useFeedName();
  const filteredFeedItems = useFilteredFeedItems();

  const [isFilteredUI, setIsFilteredUI] = useState<boolean>(isFiltered());
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);

  const hasChartAccess = useFeature("enable-charts", false);

  useEffect(() => {
    setIsFilteredUI(isFiltered());
  }, [filteredFeedItems]);

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Only to restore the existing search terms
    setSearchTerm(searchTerms);
  }, []);

  useEffect(() => {
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    if (isAdvancedSearchOpen) return;

    debounceTimeout.current = setTimeout(() => {
      filterBySearchTerms(searchTerm);
    }, 500);

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchTerm]);

  const handleSubmitAdvancedSearch = () => {
    filterBySearchTerms(searchTerm);
  };

  const onApply: onApplyFn = (range) => {
    updateDateRange(range.days, range.from, range.to, range.isCustom);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      filterBySearchTerms(searchTerm);
    }
  };

  const hasAppliedFilters = filterData.some(
    (filter) => !!filter.selectedOptions.length,
  );

  const renderNumberOfArticles = () => {
    if (feedVolume && feedVolumePercentage) {
      return (
        <span>
          <span className="font-semibold">
            {addCommasToNumbers(feedVolume)}{" "}
          </span>
          <span>{pluralize(filteredFeedItems?.length, "Article")} </span>
          {isFilteredUI &&
            feedVolumePercentage !== 0 &&
            !Number.isNaN(feedVolumePercentage) && (
              <span>({feedVolumePercentage}%)</span>
            )}
          {isFilteredUI && feedVolumePercentage === 0 && (
            <span>(Adjust filters to see more)</span>
          )}
        </span>
      );
    }

    return (
      <span>
        <span className="font-semibold">
          {addCommasToNumbers(filteredFeedItems?.length)}{" "}
        </span>
        <span>{pluralize(filteredFeedItems?.length, "Article")} </span>
        {isFilteredUI &&
          feedPercentage !== 0 &&
          !Number.isNaN(feedPercentage) && <span>({feedPercentage}%)</span>}
        {isFilteredUI && feedPercentage === 0 && (
          <span>(Adjust filters to see more)</span>
        )}
      </span>
    );
  };

  // left intentionally to compare feedVolume and filteredFeedItems.length
  console.log("filtered items length", filteredFeedItems.length);

  return (
    <>
      <div
        className={cn(
          "max-w-[956px] lg:mx-auto  z-30 sticky",
          hasChartAccess
            ? hasAppliedFilters
              ? "top-[100px]"
              : "top-[88px]"
            : "top-0",
        )}
      >
        {isAdvancedSearchOpen && (
          <div className="px-4 py-4 bg-white">
            <JollyTextField
              textArea
              label="Advanced Search"
              value={searchTerm}
              onChange={(searchTerm) => setSearchTerm(searchTerm)}
              onKeyDown={handleKeyPress}
            />
            <div className="flex justify-end gap-2 mt-2">
              <Button
                type="button"
                variant="ghost"
                onPress={() => setIsAdvancedSearchOpen(false)}
              >
                Cancel
              </Button>
              <Button variant="outline" onPress={handleSubmitAdvancedSearch}>
                Submit
              </Button>
            </div>
          </div>
        )}
        <div className="flex px-3 pt-2 items-baseline justify-between bg-white">
          <div className="text-sm flex-grow text-slate-900/95 max-w-52 flex-1 leading-tight tracking-[0.14px]">
            <div className="text-sm mb-1">
              {fetchingFeed ? (
                <Skeleton className="w-32 h-5 bg-slate-300/50" />
              ) : (
                <div className={"flex items-center"}>
                  <Checkbox
                    checked={deleteAllArticles}
                    className={"mr-4"}
                    isSelectAll={true}
                    isPartialSelect={
                      !!articleIdsToDelete.length &&
                      articleIdsToDelete.length !== allArticleIds.length
                    }
                    onCheckedChange={() =>
                      toggleDeleteAllArticles(allArticleIds)
                    }
                  />
                  {renderNumberOfArticles()}
                  <div>
                    {!hasChartAccess &&
                      feedPercentage &&
                      feedPercentage !== 100 && (
                        <div className="text-[10px] tracking-[0.1px]">
                          {isFilteredUI &&
                            feedPercentage !== 0 &&
                            !Number.isNaN(feedPercentage) && (
                              <span>
                                ({feedPercentage}% of all {feedName} coverage)
                              </span>
                            )}
                          {isFilteredUI && feedPercentage === 0 && (
                            <span>(Adjust filters to see more)</span>
                          )}
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex gap-1 items-end pb-1.5">
            {!isAdvancedSearchOpen && (
              <div className="relative flex items-center w-auto">
                <MagnifyingGlass
                  size={16}
                  className="text-gray-600 absolute left-2.5"
                />
                <Input
                  placeholder="Search for article"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={(e) => handleKeyPress(e)}
                  className={cn(
                    "w-80 mr-1 h-9 py-2 pl-[2rem] pr-[4.5rem] items-end transition-width duration-200 ease-in-out",
                  )}
                />
                <Button
                  type="button"
                  variant="input"
                  size="input"
                  onPress={() => setIsAdvancedSearchOpen(!isAdvancedSearchOpen)}
                >
                  Expand
                </Button>
              </div>
            )}
            {!hasChartAccess && (
              <DateRangePicker
                filterDays={filterDays}
                isCustomDaysRange={isCustomDaysRange}
                onApply={onApply}
                startDate={startDate}
                endDate={endDate}
              />
            )}
            <FeedSort />
            {!hasChartAccess && <FeedExport />}
          </div>
        </div>
        <div className="z-10 bg-gradient-to-b from-white via-white/50 via-70% h-4 -mx-1 to-transparent" />
      </div>
    </>
  );
};
